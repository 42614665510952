import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() => {
  return {
    container: {
      position: 'absolute',
      bottom: '16px',
      right: '16px',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      border: '6px solid #DA0767',
      backgroundColor: '#FFFFFF',
      boxSizing: 'border-box',
    },
  };
});
