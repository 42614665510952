import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() => {
  return {
    container: {
      width: '100%',
      marginBottom: 38,
      display: 'flex',
      minHeight: '48px',
    },

    textInput: {
      backgroundColor: '#FFFFFF',
      width: '384px',
      height: '48px',
      color: '#000000',
      fontFamily: 'Poppins',
      fontSize: '14px',
      borderRadius: '12px',
      border: '1px solid #EDEDED',
      borderWidth: '1px',

      '& fieldset': {
        borderColor: '#EDEDED !important',
      },

      '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type=number]': {
        '-moz-appearance': 'textfield', // For Firefox
      },
    },

    indicatorsContainer: {
      flex: 1,
      display: 'flex',
      gap: 8,
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  };
});
