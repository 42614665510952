import React from 'react';
import clsx from 'clsx';

import { LabelText, LabelTextSize } from 'src/components/common/flure';

import { Bubble } from '../bubble';
import { useStyles } from './styles';

type Props = {
  className?: string;
  isRecognized?: boolean | null;
  isSpicy?: boolean | null;
  reason?: string;
};

export const AIBubble = React.memo(({ className, isRecognized, isSpicy, reason }: Props) => {
  const classes = useStyles();
  const getEmoji = () => {
    if (isRecognized) {
      return isSpicy ? '🟠' : '🟢';
    }

    return '🔴';
  };

  return (
    <Bubble className={clsx(className, reason ? classes.cursorHelp : classes.cursorDefault)} title={reason}>
      <LabelText
        className={classes.whiteText}
        text={`${getEmoji()} AI`}
        size={LabelTextSize.ExtraSmall}
      />
    </Bubble>
  );
});
