import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '@material-ui/core';

import { usePhotoMediaSources } from 'src/services/flure-ai-decision/hooks';
import { LabelText, Option } from 'src/components/common/flure';
import { useClickOutside } from 'src/hooks';

import filterIcon from './icon_filter.svg';
import { useStyles } from './styles';
import { Filters } from '../filters';

type Props = {
  appliedFilters: string[];
  setAppliedFilters: React.Dispatch<React.SetStateAction<string[]>>;
};

export const FilterButton = React.memo(({ appliedFilters, setAppliedFilters }: Props) => {
  const { mediaSourcesState, fetchMediaSources } = usePhotoMediaSources();
  const [initialOptions, setInitialOptions] = useState<Option[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const classes = useStyles();
  const filtersRef = useRef<HTMLDivElement>(null);

  const closeFilters = useCallback(() => {
    setShowFilters(false);
  }, []);

  useEffect(() => {
    fetchMediaSources();
  }, [fetchMediaSources]);

  useEffect(() => {
    if (mediaSourcesState.value) {
      setInitialOptions(mediaSourcesState.value.map((it) => ({ text: it, value: it })));
    }
  }, [mediaSourcesState.value]);

  useClickOutside(filtersRef, closeFilters);

  return (
    <div ref={filtersRef}>
      <Button className={classes.button} onClick={() => setShowFilters((it) => !it)}>
        <img className={classes.filterIcon} src={filterIcon} alt="filter" />
        <LabelText text={`Filter (${appliedFilters.length})`} />
      </Button>
      {showFilters && (
        <div className={classes.filtersContainer}>
          <Filters
            initialOptions={initialOptions}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            closeFilters={closeFilters}
          />
        </div>
      )}
    </div>
  );
});
