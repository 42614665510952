import React from 'react';

import { Gender } from 'src/types/user';

import { useStyles } from './styles';

type Props = {
  gender?: Gender;
  toggleUserGender: () => void;
};

export const ModerationProfileInfoGenderButton = (props: Props) => {
  const { gender, toggleUserGender } = props;

  const isMale = gender === 'mal';

  const classes = useStyles({ isMale });

  return (
    <button type="button" className={classes.genderContainer} onClick={toggleUserGender}>
      <span className={classes.genderText}>{isMale ? '👨🏻' : '👩🏻'}</span>
    </button>
  );
};
