import { AxiosResponse } from 'axios';

import { Http } from 'src/network/http';

import {
  AIDecisionForTextResponse,
  AIDecisionsForPhotosResponse,
  PhotoMediaSourcesResponse,
  AIDecisionPhotosHistoryResponse,
  AIDecisionPhotoType,
} from './types';

const getAIDecisionPhotosHistory = ({
  omit = 0,
  select = 100,
  type,
  mediaSources,
}: {
  omit: number;
  select: number;
  type: AIDecisionPhotoType;
  mediaSources?: string[];
}) =>
  Http.shared()
    .instance.get<AIDecisionPhotosHistoryResponse>('moderation/content/aidecision/photos', {
      params: {
        type,
        omit,
        select,
        mediaSources,
      },
      paramsSerializer: {
        indexes: null,
      },
    })
    .then((response) => {
      return response.data.models || [];
    });

const getPhotoMediaSources = () => {
  return Http.shared()
    .instance.get<PhotoMediaSourcesResponse>('moderation/content/aidecision/mediasources')
    .then((response) => {
      return response.data.mediaSources || [];
    });
};

const getAIDecisionForText = (text: string) => {
  return Http.shared()
    .instance.post('/moderation/content/aidecision/text', {
      text,
    })
    .then((response: AxiosResponse<AIDecisionForTextResponse>) => response.data);
};

const getAIDecisionsForPhotos = ({
  userId,
  select = 100,
  type,
}: {
  userId: string;
  select?: number;
  type: AIDecisionPhotoType;
}) => {
  return Http.shared()
    .instance.get(`/moderation/content/${userId}/aidecisions/photos`, {
      params: { select, type },
    })
    .then((response: AxiosResponse<AIDecisionsForPhotosResponse>) => response.data?.photos);
};

export const aiDecisionRequest = {
  getAIDecisionPhotosHistory,
  getPhotoMediaSources,
  getAIDecisionForText,
  getAIDecisionsForPhotos,
};
