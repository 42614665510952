import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 0px 70px',
    justifySelf: 'center',
    alignSelf: 'flex-start',
  },

  button: {
    width: '420px',
  },

  subtitle: {
    display: 'flex',
    justifySelf: 'flex-start',
    marginBottom: '24px',
  },

  checkBoxesContainer: {
    marginBottom: '32px',
    flexDirection: 'row',
    columnGap: '8px',
  },

  audienceContainer: {
    justifyContent: 'flex-start',
    width: '910px',
  }
});
