import { isEmpty } from 'lodash';

const emptyCampus = 'emptyCampus';
const otherCountry = 'otherCountry';

export const prepareData = ({
  countries,
  campuses,
}: {
  countries: string[];
  campuses: string[];
}) => {
  let preparedCountries: string[]= countries;
  let preparedCampuses: string[] = campuses;

  if (!isEmpty(countries) && !isEmpty(campuses)) {
    preparedCountries = countries;
    preparedCampuses = [emptyCampus, ...campuses];
  } else if (!isEmpty(countries)) {
    preparedCountries = countries;
    preparedCampuses = [emptyCampus];
  } else if (!isEmpty(campuses)) {
    preparedCountries = [otherCountry, 'US'];
    preparedCampuses = campuses;
  }

  return {
    preparedCountries,
    preparedCampuses,
  };
};
