import { useAsyncFn } from 'react-use';

import { flureChatRequest } from 'src/network/flure-chat';
import { ChatAuditoryParams } from 'src/network/flure-chat/types';
import { useAuth } from '../auth';
import { prepareData } from './prepare-data';

export const useMessages = () => {
  const { me } = useAuth();
  const operatorId = me?.id;

  const [messagesState, fetchMessages] = useAsyncFn(
    async ({ countries, genderIdentities, campuses }: ChatAuditoryParams) => {
      const { preparedCountries, preparedCampuses } = prepareData({ countries, campuses });

      try {
        const result = operatorId
          ? await flureChatRequest.getMessagesForSelectedAuditory(operatorId, {
              countries: preparedCountries,
              campuses: preparedCampuses,
              genderIdentities,
            })
          : { messages: [] };
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: false },
  );

  return { messagesState, fetchMessages };
};
