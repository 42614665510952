import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import { CloseIcon } from '@material-ui/data-grid';

import { ModerationDialogModal } from 'src/components/Moderation/screens/UserModeration/once/components/dialog-modal';

import { useStyles } from './styles';

type Props = {
  onTimeoutModalClose: () => void;
};

export const ModerationTimeoutModal = (props: Props) => {
  const { onTimeoutModalClose } = props;

  const classes = useStyles();

  const onClose = () => onTimeoutModalClose();

  return (
    <ModerationDialogModal open onClose={onClose}>
      <span className={classes.modalTitle}>Timeout</span>
      <span className={classes.modalSubtitle}>
        You have been inactive for too long. Please start again to resume moderation.
      </span>

      <Button variant="outlined" className={classes.button} onClick={onClose}>
        Got it
      </Button>

      <IconButton aria-label="close" onClick={onClose} className={classes.modalIconContainer}>
        <CloseIcon className={classes.modalIcon} />
      </IconButton>
    </ModerationDialogModal>
  );
};
