import React from 'react';
import clsx from 'clsx';

import { LabelText } from 'src/components/common/flure';
import { AIDecisionPhotoType } from 'src/network/flure-ai-decision/types';
import { Button } from '@material-ui/core';

import { FilterButton } from '../filter-button';
import { useStyles } from './styles';

type Props = {
  title: string;
  appliedFilters: string[];
  setAppliedFilters: React.Dispatch<React.SetStateAction<string[]>>;
  photoType?: AIDecisionPhotoType;
  setPhotoType?: React.Dispatch<React.SetStateAction<AIDecisionPhotoType>>;
};

export const ScreenHeader = React.memo(
  ({ title, appliedFilters, setAppliedFilters, photoType, setPhotoType }: Props) => {
    const classes = useStyles();
    const showButtons = !!photoType;

    return (
      <div className={classes.header}>
        {showButtons && (
          <div className={classes.buttonsContainer}>
            <Button
              className={clsx([
                classes.button,
                photoType === AIDecisionPhotoType.ModerationExperiment && classes.buttonActive,
              ])}
              onClick={() => setPhotoType?.(AIDecisionPhotoType.ModerationExperiment)}
            >
              <LabelText text={`${title} 🧪`} />
            </Button>
            <Button
              className={clsx([classes.button, photoType === AIDecisionPhotoType.Moderation && classes.buttonActive])}
              onClick={() => setPhotoType?.(AIDecisionPhotoType.Moderation)}
            >
              <LabelText text={title} />
              <div className={classes.bubble}>
                <LabelText text="PROD" />
              </div>
            </Button>
          </div>
        )}
        {!showButtons && <LabelText text={title} />}
        <FilterButton appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} />
      </div>
    );
  },
);
