import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme, { isMale: boolean }>(() => {
  return {
    genderContainer: {
      flexShrink: 0,
      width: '72px',
      height: '72px',
      backgroundColor: ({ isMale }) => (isMale ? '#8BAAF8' : '#FFAAEC'),
      borderRadius: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '8px',
      border: '1px solid rgba(33, 33, 33, 0.15)',
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',

      '&:hover': {
        cursor: 'pointer',
        opacity: 0.8,
      },
    },

    genderText: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '48px',
    },
  };
});
