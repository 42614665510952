import React from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import { BubbleCheckBoxesList, Option } from 'src/components/common/flure';

import { useStyles } from './styles';

type Props = {
  genderOptions: Option[];
  countriesOptions: Option[];
  campusesOptions: Option[];
  selectedGenders: string[];
  setSelectedGenders?: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCountries: string[];
  setSelectedCountries?: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCampuses: string[];
  setSelectedCampuses?: React.Dispatch<React.SetStateAction<string[]>>;
  className?: string;
  disabled?: boolean;
};

const AudienceSelectorView: React.FC<Props> = (props) => {
  const {
    selectedGenders,
    setSelectedGenders,
    selectedCountries,
    setSelectedCountries,
    selectedCampuses,
    setSelectedCampuses,
    className,
    genderOptions,
    countriesOptions,
    campusesOptions,
    disabled,
  } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <BubbleCheckBoxesList
        options={genderOptions}
        selected={selectedGenders}
        setSelected={setSelectedGenders}
        disabled={disabled}
      />
      <div className={classes.row}>
        <BubbleCheckBoxesList
          options={countriesOptions}
          selected={selectedCountries}
          setSelected={setSelectedCountries}
          disabled={disabled}
          allowEmpty={!isEmpty(selectedCampuses)}
        />
        <BubbleCheckBoxesList
          options={campusesOptions}
          selected={selectedCampuses}
          setSelected={setSelectedCampuses}
          disabled={disabled}
          allowEmpty={!isEmpty(selectedCountries)}
        />
      </div>
    </div>
  );
};

export const AudienceSelector = React.memo(AudienceSelectorView);
