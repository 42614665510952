import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() => {
  return {
    modalTitle: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '36px',
      marginBottom: '16px',
    },

    modalSubtitle: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: '32px',
      textAlign: 'center',
    },

    modalIconContainer: {
      position: 'absolute',
      top: '8px',
      right: '8px',
      '&:hover': {
        opacity: 0.8,
      },
    },

    modalIcon: {
      color: '#000000',
      width: '24px',
      height: '24px',
    },

    button: {
      backgroundColor: '#000000',
      height: '48px',
      width: '135px',
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: '#000000',
        opacity: 0.8,
      },

      '& .MuiButton-label': {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FFFFFF',
        textTransform: 'none',
      },
    },
  };
});
