import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  buttonCommon: {
    borderRadius: '62px',
    height: '50px',
    minWidth: '60px',
    width: '100%',
    padding: '13px 32px',
  },

  loader: {
    height: '30px !important',
    width: '30px !important',
    color: 'white !important',
  },

  titleCommon: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    textAlign: 'center',
    textTransform: 'none',
    fontFamily: 'Poppins',
  },

  defaultButton: {
    backgroundColor: '#000',
    color: '#fff',

    '&:hover': {
      backgroundColor: '#000',
      opacity: 0.8,
      color: '#fff',
    },
    '&:disabled': {
      backgroundColor: 'rgb(242, 218, 245)',
      color: '#fff',
    },
  },

  strokeButton: {
    backgroundColor: '#transparent',
    border: '1px solid #000',
    color: '#000',

    '&:hover': {
      opacity: 0.8,
    },
    '&:disabled': {
      opacity: 0.8,
    },
  },

  pinkButton: {
    backgroundColor: 'rgb(242, 218, 245)',
    color: '#000',

    '&:hover': {
      backgroundColor: 'rgb(242, 218, 245)',
      opacity: 0.8,
    },
    '&:disabled': {
      backgroundColor: 'rgb(242, 218, 245)',
      opacity: 0.8,
      color: '#fff',
    },
  },
});
