import { makeStyles, Theme } from '@material-ui/core';

import { buttonBgHelper } from '../styles';

export const useStyles = makeStyles<Theme>(() => {
  return {
    contentContainer: {
      minWidth: '480px',
      padding: '40px',
    },

    deleteButton: {
      marginRight: '16px',
      ...buttonBgHelper('#F31D1D', '#FFFFFF'),
    },

    modalTitle: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '36px',
      marginBottom: '16px',
    },

    modalSubtitle: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: '32px',
      textAlign: 'center',
    },

    modalIconContainer: {
      position: 'absolute',
      top: '8px',
      right: '8px',
      '&:hover': {
        opacity: 0.8,
      },
    },

    modalIcon: {
      color: '#000000',
      width: '24px',
      height: '24px',
    },

    modalButtonContainer: {},

    modalDeleteButton: {
      marginLeft: '16px',
      ...buttonBgHelper('#FFFFFF', '#F31D1D'),
    },
  };
});
