import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '400px',
    height: 'calc(100vh - 150px)',
    margin: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  button: {
    backgroundColor: '#000000',
    height: '64px',
    width: '100%',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#000000',
      opacity: 0.8,
    },

    '& .MuiButton-label': {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#FFFFFF',
      textTransform: 'none',
    },
  },

  countText: {
    fontFamily: 'Poppins',
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '48px',
    marginBottom: '4px',
  },

  descriptionText: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
  },

  countDescText: {
    marginBottom: '32px',
  },

  orText: {
    marginTop: '16px',
    marginBottom: '16px',
  },

  formControl: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },

  textInput: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '48px',
    color: 'black',
    fontFamily: 'Poppins',
    fontSize: '14px',
    borderRadius: '12px',
    border: '1px solid #EDEDED',
    borderWidth: '1px',

    '& fieldset': {
      borderColor: '#EDEDED !important',
    },
    marginRight: '16px',

    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type=number]': {
        '-moz-appearance': 'textfield', // For Firefox
      },
  },

  searchButton: {
    backgroundColor: '#FFFFFF',
    color: '#212121',
    borderRadius: '12px',
    border: '1px solid #EDEDED',
    paddingLeft: '24px',
    paddingRight: '24px',
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',

    '&:hover': {
      backgroundColor: '#FFFFFF',
      opacity: 0.8,
    },

    '& .Mui-disabled': {
      color: '#EDEDED',
    },

    '& .MuiButton-label': {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '21px',
      textTransform: 'none',
    },
  },

  loadingContainer: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
