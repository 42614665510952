import { makeStyles, Theme } from '@material-ui/core';

import { ONCE } from 'src/types/once';

const mapGroupToColor = {
  [ONCE.ModerationProfileGroup.Common]: '#212121',
  [ONCE.ModerationProfileGroup.Unapproved]: '#FF7A2F',
  [ONCE.ModerationProfileGroup.Scam]: '#F31D1D',
};

export const useStyles = makeStyles<Theme, { group: ONCE.ModerationProfileGroup }>(() => {
  return {
    container: {
      minHeight: '88px',
      padding: '16px 40px',
      borderRadius: '12px',
      border: '1px solid #EDEDED',
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
      marginBottom: '8px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      overflow: 'hidden',
    },

    baseText: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      lineHeight: '21px',
      color: '#212121',
      flex: 1.4,
      marginRight: '24px',
      textAlign: 'start',
    },

    initiatedByText: {
      flex: 1,
    },

    groupText: {
      flex: 1.7,
      color: ({ group }) => mapGroupToColor[group],
    },

    reasonContainer: {
      flex: 2.6,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },

    reasonText: {
      marginRight: '0px',
      wordBreak: 'break-word',
    },
  };
});
