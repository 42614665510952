import React, { useCallback } from 'react';
import clsx from 'clsx';

import { Resources } from 'src/resources';
import { TitleText, TitleTextSize } from 'src/components/common/flure';
import {
  MediaUploadInput,
  MediaUploadInputSize,
  PushPreview,
  TextInput,
} from 'src/components/FlureChat/components';

import { useStyles } from './styles';

type Props = {
  pushTitle: string | undefined;
  setPushTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
  pushSubtitle: string | undefined;
  setPushSubtitle: React.Dispatch<React.SetStateAction<string | undefined>>;
  pushImage: string | undefined;
  setPushImage: React.Dispatch<React.SetStateAction<string | undefined>>;
  couldCreate?: boolean;
  pushImagePreview?: string | undefined;
  setPushImagePreview?: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const PushWithPreviewView: React.FC<Props> = (props) => {
  const {
    pushTitle,
    setPushTitle,
    pushSubtitle,
    setPushSubtitle,
    pushImage,
    setPushImage,
    couldCreate,
    pushImagePreview,
    setPushImagePreview = () => null,
  } = props;
  const classes = useStyles();

  const onPushTitleChange = useCallback(
    (event) => {
      setPushTitle(event.target.value);
    },
    [setPushTitle],
  );

  const onPushSubtitleChange = useCallback(
    (event) => {
      setPushSubtitle(event.target.value);
    },
    [setPushSubtitle],
  );

  const onPushImageChange = useCallback(
    (source: string | undefined) => {
      setPushImage(source);
    },
    [setPushImage],
  );

  return (
    <div className={clsx([classes.pushBlockContainer, !couldCreate && classes.justifyStart])}>
      {!!couldCreate && (
        <div className={classes.leftContainer}>
          <div className={classes.pushContentContainer}>
            <TitleText
              className={classes.subtitle}
              text={Resources.strings.flureChat.pushNotification}
              size={TitleTextSize.Medium}
            />
            <div className={classes.inputsContainer}>
              <TextInput
                placeholder={Resources.strings.flureChat.pushNotificationTitleInputPlaceholder}
                onChange={onPushTitleChange}
                maxLength={45}
              />
              <TextInput
                placeholder={Resources.strings.flureChat.pushNotificationSubtitleInputPlaceholder}
                onChange={onPushSubtitleChange}
                multiline
                maxLength={95}
              />
              <MediaUploadInput
                size={MediaUploadInputSize.Small}
                onChange={onPushImageChange}
                mediaPreview={pushImagePreview}
                setMediaPreview={setPushImagePreview}
              />
            </div>
          </div>
        </div>
      )}

      <div className={clsx([classes.rightContainer, !couldCreate && classes.zeroPaddingTop])}>
        {!couldCreate && (
          <TitleText
            className={classes.subtitle}
            text={Resources.strings.flureChat.pushNotification}
            size={TitleTextSize.Medium}
          />
        )}
        <PushPreview
          title={pushTitle || Resources.strings.flureChat.pushNotificationDefaultTitle}
          subtitle={pushSubtitle || Resources.strings.flureChat.pushNotificationDefaultSubtitle}
          imageSource={pushImage}
        />
      </div>
    </div>
  );
};

export const PushWithPreview = React.memo(PushWithPreviewView);
