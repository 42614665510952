import { useAsyncFn } from 'react-use';

import { onceModerationRequest } from 'src/network/moderation/once';

export const useModerationGroupsDecisions = () => {
  const [moderationGroupsDecisionsState, fetchModerationGroupsDecisions] = useAsyncFn(async (userId: string) => {
    const result = await onceModerationRequest.getModerationGroupsDecisions(userId);

    return result;
  }, []);

  return {
    moderationGroupsDecisionsState,
    fetchModerationGroupsDecisions,
  };
};
