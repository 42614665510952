import React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';

import { ONCE } from 'src/types/once';

import { useStyles } from './styles';

type Props = {
  selectedCategories: Record<string, ONCE.ContentDecisionCategoryLevelType>;
  wrapItems?: boolean;
  categories: Record<string, string>;
  setCategoryLevel?: (category: string, level: ONCE.ContentDecisionCategoryLevelType) => void;
};

export const ModerationContentCategories = (props: Props) => {
  const { selectedCategories, wrapItems, categories, setCategoryLevel } = props;

  const classes = useStyles({ wrapItems });

  const onCategoryClick = (categoryKey: string) => {
    const level = selectedCategories?.[categoryKey];

    if (setCategoryLevel) {
      const newLevel =
        level === ONCE.ContentDecisionCategoryLevelType.Red
          ? ONCE.ContentDecisionCategoryLevelType.Green
          : ONCE.ContentDecisionCategoryLevelType.Red;

      setCategoryLevel(categoryKey, newLevel);
    }
  };

  return (
    <div className={classes.container}>
      {Object.keys(categories).map((key) => {
        const level = selectedCategories?.[key];

        return (
          <button
            type="button"
            key={key}
            className={clsx([
              classes.buttonContainer,
              level === ONCE.ContentDecisionCategoryLevelType.Yellow && classes.itemYellowContainer,
              level === ONCE.ContentDecisionCategoryLevelType.Red && classes.itemRedContainer,
            ])}
            disabled={!setCategoryLevel}
            onClick={() => onCategoryClick(key)}
          >
            <Typography
              className={clsx([
                classes.itemText,
                level === ONCE.ContentDecisionCategoryLevelType.Yellow && classes.itemYellowText,
                level === ONCE.ContentDecisionCategoryLevelType.Red && classes.itemRedText,
              ])}
            >
              {categories[key as keyof typeof categories]}
            </Typography>
          </button>
        );
      })}
    </div>
  );
};
