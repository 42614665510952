import React, { useState, useCallback, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { Button, Box, FormControl, OutlinedInput, Typography, CircularProgress } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

import { Layout } from 'src/components/App/views/Layout';
import { useAuth } from 'src/services/auth';
import { useKeyboardEventListener } from 'src/components/Moderation/utils/keyboard';
import { useModerationQueueCount } from 'src/components/Moderation/hooks/once';
import { getUserRolesMap } from 'src/components/config';

import { useStyles } from './styles';

export const StartOnceModerationProcessScreen = () => {
  const classes = useStyles();

  const [searchText, setSearchText] = useState('');

  const history = useHistory();
  const { me } = useAuth();

  const rolesMap = useMemo(() => {
    const roles = me?.roles || [];

    return getUserRolesMap(roles);
  }, [me?.roles]);
  const { isAdministrator } = rolesMap;

  const { queueCountLoading, queueCount, fetchModerationQueueCount } = useModerationQueueCount();

  const onTextChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  const keyPressListener = useCallback(
    (event: KeyboardEvent) => {
      if (isAdministrator && searchText && !event.defaultPrevented && event.key === 'Enter') {
        history.push(`/moderation-new/user/${searchText}`);
      }
    },
    [history, isAdministrator, searchText],
  );

  useKeyboardEventListener(keyPressListener);

  useEffect(() => {
    fetchModerationQueueCount();
    const interval = setInterval(fetchModerationQueueCount, 60000);
    return () => clearInterval(interval);
  }, [fetchModerationQueueCount]);

  const searchButtonDisabled = searchText.length < 6;

  return (
    <Layout containerSize="lg">
      <Box className={classes.container}>
        <Typography className={classes.countText}>{queueCount}</Typography>
        <Typography className={clsx(classes.descriptionText, classes.countDescText)}>users in the queue</Typography>
        {queueCount ? (
          <Button component={Link} to="/moderation-new/content" className={classes.button}>
            Start Moderation
          </Button>
        ) : (
          <Button className={classes.button} onClick={fetchModerationQueueCount}>
            Refresh
          </Button>
        )}

        {isAdministrator && (
          <>
            <Typography className={clsx(classes.descriptionText, classes.orText)}>or</Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <OutlinedInput
                value={searchText}
                onChange={onTextChange}
                placeholder="Search by ID"
                className={classes.textInput}
                labelWidth={0}
                type="number"
              />
              <Button
                variant="outlined"
                component={Link}
                to={`/moderation-new/user/${searchText}`}
                className={classes.searchButton}
                disabled={searchButtonDisabled}
              >
                Search
              </Button>
            </FormControl>
          </>
        )}

        {queueCountLoading && (
          <div className={classes.loadingContainer}>
            <CircularProgress color="secondary" />
          </div>
        )}
      </Box>
    </Layout>
  );
};
