import { useAsyncFn } from 'react-use';

import { onceModerationRequest } from 'src/network/moderation/once';

export const useModerationQueueCount = () => {
  const [moderationQueueCountState, fetchModerationQueueCount] = useAsyncFn(async () => {
    const result = await onceModerationRequest.getModerationQueueCount();

    return result;
  }, []);

  return {
    queueCountLoading: moderationQueueCountState.loading,
    queueCount: moderationQueueCountState?.value?.count || 0,
    fetchModerationQueueCount,
  };
};
