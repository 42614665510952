import React from 'react';
import clsx from 'clsx';

import { ONCE } from 'src/types/once';
import { ModerationContent, ModerationContentItem } from 'src/components/Moderation/screens/UserModeration/once/utils';

import { useStyles } from './styles';
import { ModerationProfileMediaCard } from './card';

type Props = {
  isAdministrator: boolean;
  setModerationContent: (
    field: keyof ModerationContent,
    category: string,
    level: ONCE.ContentDecisionCategoryLevelType,
    mediaContent?: string,
  ) => void;
  setProfileThumbnail: (basename: string) => void;
  userId: string;
  profileMedia: ModerationContentItem[];
  profileThumbnail?: string;
};

export const ModerationProfileMedia = (props: Props) => {
  const { isAdministrator, setModerationContent, setProfileThumbnail, userId, profileMedia, profileThumbnail } = props;

  const classes = useStyles();

  const userHasMedia = profileMedia.length > 0;

  return (
    <div className={clsx(classes.container, userHasMedia && classes.containerActive)}>
      {profileMedia.map((content) => {
        return (
          <ModerationProfileMediaCard
            key={content.content}
            isAdministrator={isAdministrator}
            moderationContent={content}
            setModerationContent={setModerationContent}
            setProfileThumbnail={setProfileThumbnail}
            userId={userId}
            profileThumbnail={profileThumbnail}
          />
        );
      })}
    </div>
  );
};
