import { makeStyles, Theme } from '@material-ui/core';

const moderatedBorderSize = 8;

export const useStyles = makeStyles<Theme, { isThumbnail: boolean; isToModerate?: boolean }>(() => {
  return {
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },

    imageButton: {
      position: 'relative',
      backgroundColor: '#FFFFFF',
      padding: '0px',
      border: 'none',
      borderRadius: '12px',
      cursor: 'pointer',

      '&:hover': {
        opacity: 0.8,
      },

      '&:disabled': {
        cursor: 'default',
        opacity: 1,
      },
    },

    moderationBorder: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: '12px',
      border: ({ isToModerate }) => (isToModerate ? `${moderatedBorderSize}px solid #DA0767` : '1px solid #EDEDED'),
    },

    imageContainer: {
      display: 'flex',
      overflow: 'hidden',
      borderRadius: '12px',
    },

    image: {
      width: '192px',
      height: '256px',
      objectFit: 'cover',
    },

    mainButtonContainer: {
      marginTop: '8px',
      backgroundColor: ({ isThumbnail }) => (isThumbnail ? '#000000' : '#FFFFFF'),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '24px',
      border: '1px solid #EDEDED',
      minWidth: '50px',
      minHeight: '24px',
      padding: '0px',

      '&:hover': {
        cursor: 'pointer',
        opacity: 0.8,
      },
    },

    mainButtonText: {
      fontFamily: 'Poppins',
      fontSize: '10px',
      lineHeight: '15px',
      color: ({ isThumbnail }) => (isThumbnail ? '#FFFFFF' : '#000000'),
    },

    authorContainer: {
      position: 'absolute',
      top: 8,
      left: 8,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      borderRadius: '8px',
      height: 24,
      padding: '0px 12px',
      display: 'flex',
      alignItems: 'center',
    },

    authorText: {
      fontFamily: 'Poppins',
      fontSize: '10px',
      lineHeight: '15px',
      color: '#FFFFFF',
    },
  };
});
