import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<
  Theme,
  {
    hasBottomPanel?: boolean;
  }
>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100dvh - 70px)',
    bottom: 0,
    left: 0,
    position: 'fixed',
    overflowY: 'auto',
    width: '100%',
    justifyContent: (props) => (props.hasBottomPanel ? 'space-between' : 'flex-start'),
  },

  placeholderContainer: {
    height: '70vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  processingContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    height: 'calc(100vh - 100px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
});
