import { useAsyncFn } from 'react-use';

import { ONCE } from 'src/types/once';
import { onceModerationRequest } from 'src/network/moderation/once';

const fallbackArray: ONCE.ContentDecision[] = [];

export const useModerationContentDecisions = () => {
  const [moderationContentDecisionsState, fetchModerationContentDecisions] = useAsyncFn(
    async (userId: string, decisionType?: string) => {
      const result = await onceModerationRequest.getModerationContentDecisions(userId, decisionType);

      return result;
    },
    [],
  );

  return {
    moderationContentDecisionsState,
    moderationContentDecisions: moderationContentDecisionsState.value?.decisions ?? fallbackArray,
    fetchModerationContentDecisions,
  };
};
