import { ONCE } from 'src/types/once';

import { ModerationContent, ModerationContentItem } from './types';

export const sortAndFilterProfileMedia = (
  contentToModerate: ModerationContent['newMedia'],
  moderationContent: ModerationContent['newMedia'],
  thumbnail: string | undefined,
  rejectedContentDecision: ONCE.ContentDecision[],
): ModerationContentItem[] => {
  const sorted = Object.keys(moderationContent || {}).sort((a, b) => {
    const aHasDecision = moderationContent?.[a].isToModerate || false;
    const bHasDecision = moderationContent?.[b]?.isToModerate || false;

    if (aHasDecision !== bHasDecision) {
      return Number(bHasDecision) - Number(aHasDecision);
    }

    const aIsThumbnail = thumbnail && moderationContent?.[a].content?.startsWith(thumbnail);
    const bIsThumbnail = thumbnail && moderationContent?.[b].content?.startsWith(thumbnail);

    if (aIsThumbnail !== bIsThumbnail) {
      return Number(bIsThumbnail) - Number(aIsThumbnail);
    }

    const aIsReadonly = moderationContent?.[a].isReadonly || false;
    const bIsReadonly = moderationContent?.[b]?.isReadonly || false;

    return Number(bIsReadonly) - Number(aIsReadonly);
  });

  const rejectedContentSet = new Set(rejectedContentDecision.map((contentDecision) => contentDecision.content));

  return sorted.reduce<ModerationContentItem[]>((acc, curr) => {
    if (contentToModerate?.[curr] && !rejectedContentSet.has(curr)) {
      acc.push(contentToModerate[curr]);
    }

    return acc;
  }, []);
};
