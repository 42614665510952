import React from 'react';
import Switch from 'react-ios-switch';

import { TitleText, TitleTextSize } from 'src/components/common/flure';
import { useStyles } from './styles';
import dragIcon from './drag-icon.svg';

type Props = {
  title: string;
  children: React.ReactNode;
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  switchDisabled?: boolean;
};

export const FieldWrapper = React.memo(({ children, title, enabled, setEnabled, switchDisabled = false }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <div className={classes.titleWithIcon}>
          <img className={classes.dragIcon} src={dragIcon} alt="drag" />
          <TitleText text={title} size={TitleTextSize.Small} />
        </div>
        <Switch
          checked={enabled}
          onChange={(isChecked: boolean) => {
            setEnabled(isChecked);
          }}
          onColor="#F2DAF5"
          offColor="#F4F4F4"
          disabled={switchDisabled}
        />
      </div>
      <div className={classes.childrenContainer}>{children}</div>
    </div>
  );
});
