import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';

import { ModerationHistoryMedia, MediaModerationReason } from 'src/network/moderation/types';
import { getMediaSource } from 'src/components/Moderation/utils/image-source';
import { AIBubble } from 'src/components/common/flure';
import { AIDecisionForPhoto } from 'src/network/flure-ai-decision/types';
import { formatAIDecisionText } from 'src/utils/format-ai-decision-text';

import { ImageWithLogger } from '../image-with-logger';
import { VideoWithLogger } from '../video-with-logger';
import { useStyles } from './styles';
import { useAuth } from '../../../../services/auth';

const ReasonMapping = {
  [MediaModerationReason.Scam]: 'Scam',
  [MediaModerationReason.Trash]: 'Trash',
  [MediaModerationReason.EighteenPlus]: '18+',
  [MediaModerationReason.NoUser]: 'No user',
  [MediaModerationReason.SamePhoto]: 'Same Media',
  [MediaModerationReason.RacyPhoto]: 'Racy Media',
  [MediaModerationReason.CelebrityPhoto]: 'Celebrity',
  [MediaModerationReason.AdultPhoto]: 'Adult Media',
  [MediaModerationReason.Approve]: '',
};

type Props = {
  media: ModerationHistoryMedia;
  userId: string;
  onMediaPress: (baseName: string, mediaType: string) => () => void;
  clickable: boolean;
  showModeratorName: boolean;
  aiDecision?: AIDecisionForPhoto;
};

export const MediaModerationItem: React.FC<Props> = (props) => {
  const { userId, media, onMediaPress, clickable, showModeratorName, aiDecision } = props;
  const { baseName, basePath, reason, initiator } = media;
  const classes = useStyles({ clickable });
  const mediaType = basePath?.includes('photos') ? 'image' : 'video';
  const topLabelText = showModeratorName ? initiator?.name : ReasonMapping[reason];
  const topLabelColor = showModeratorName ? '#2DE440' : '#ED654C';
  const { me } = useAuth();

  const isPrivate = media.tags.includes('hidden');
  const token = isPrivate ? me?.token : '';

  const mediaSource = useMemo(() => getMediaSource(userId, baseName, mediaType, token), [
    baseName,
    mediaType,
    userId,
    token,
  ]);

  const isVideo = mediaType.includes('video');
  const onClick = useMemo(() => (clickable ? onMediaPress(baseName, mediaType) : undefined), [
    baseName,
    clickable,
    mediaType,
    onMediaPress,
  ]);

  return (
    <Box className={classes.mediaContainer} onClick={onClick}>
      {isVideo ? (
        <VideoWithLogger
          className={classes.media}
          src={mediaSource}
          basename={baseName}
          autoPlay
          loop
          topLabel={topLabelText}
          topLabelColor={topLabelColor}
        />
      ) : (
        <ImageWithLogger
          className={classes.media}
          src={mediaSource}
          basename={baseName}
          alt="userPhoto"
          topLabel={topLabelText}
          topLabelColor={topLabelColor}
        />
      )}
      {!!aiDecision && (
        <AIBubble
          className={classes.aiBubble}
          isRecognized={aiDecision.approved}
          isSpicy={aiDecision.spicy}
          reason={formatAIDecisionText(aiDecision)}
        />
      )}
    </Box>
  );
};
