import { makeStyles, Theme } from '@material-ui/core';

const moderatedBorderSize = 8;

export const useStyles = makeStyles<Theme, { bold: boolean; mt?: number; isToModerate?: boolean }>(() => {
  return {
    infoContainer: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      minHeight: '72px',
      padding: '12px 24px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
      marginTop: ({ mt }) => (mt ? `${mt}px` : 'unset'),
      border: 'none',
      borderRadius: '12px',
      cursor: 'pointer',
      textAlign: 'left',

      '&:hover': {
        opacity: 0.8,
      },

      '&:disabled': {
        cursor: 'default',
        backgroundColor: '#FFFFFF',
        opacity: 1,
        color: '#000000',
      },
    },

    moderationBorder: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: '12px',
      border: ({ isToModerate }) => (isToModerate ? `${moderatedBorderSize}px solid #DA0767` : '1px solid #EDEDED'),
    },

    infoText: {
      fontFamily: 'Poppins',
      fontWeight: ({ bold }) => (bold ? 700 : 400),
      fontSize: '16px',
      lineHeight: '24px',
      wordWrap: 'break-word',
      overflow: 'hidden',
    },
  };
});
