import React, { useRef, useState } from 'react';

import { ONCE } from 'src/types/once';
import { ModerationContent, ModerationContentItem } from 'src/components/Moderation/screens/UserModeration/once/utils';
import { ModerationContentDialog } from 'src/components/Moderation/screens/UserModeration/once/components/moderation-content-dialog';

import { ModeratedTag } from '../../moderated-tag';
import { ModerationReadOnlyLabel } from '../../readonly-label';

import { useStyles } from './styles';

type Props = {
  bold?: boolean;
  field: keyof Omit<ModerationContent, 'newMedia'>;
  mt?: number;
  moderationContent: ModerationContentItem;
  setModerationContent: (
    field: keyof ModerationContent,
    category: string,
    level: ONCE.ContentDecisionCategoryLevelType,
  ) => void;
  contentFormatter?: (content: string) => string;
};

export const ModerationProfileInfoText = (props: Props) => {
  const { contentFormatter, bold = false, field, mt, moderationContent, setModerationContent } = props;

  const { content, categoryLevels, isModerated, isToModerate, isReadonly } = moderationContent;

  const classes = useStyles({ bold, mt, isToModerate });

  const textRef = useRef<HTMLSpanElement>(null);

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [popoverAnchorEl, setAnchorEl] = useState<Element>();

  const handleCardClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    setDialogOpen(false);
  };

  const setCategoryLevel = (category: string, level: ONCE.ContentDecisionCategoryLevelType) => {
    setModerationContent(field, category, level);
  };

  return (
    <>
      <button type="button" className={classes.infoContainer} onClick={handleCardClick} disabled={isReadonly}>
        <span ref={textRef} className={classes.infoText}>
          {contentFormatter ? contentFormatter(content) : content}
        </span>

        <div className={classes.moderationBorder} />
        {isReadonly && <ModerationReadOnlyLabel />}
        {isModerated && <ModeratedTag />}
      </button>

      <ModerationContentDialog
        contentType={ONCE.ContentDecisionConentType.Text}
        content={content}
        categoryLevels={categoryLevels}
        handleClose={handleClose}
        setCategoryLevel={setCategoryLevel}
        isOpen={isDialogOpen}
        popoverAnchorEl={popoverAnchorEl}
        popoverAnchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        popoverTransformOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </>
  );
};
