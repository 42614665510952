export namespace ONCE {
  export enum ContentDecisionConentType {
    Text = 'text',
    Photo = 'photo',
    Message = 'message',
    Thumbnail = 'thumbnail',
  }

  export enum ContentDecisionSource {
    About = 'about',
    Name = 'name',
    Occupation = 'occupation',
  }

  export enum ContentDecisionType {
    Approve = 'approve',
    ToModeration = 'to-moderation',
    Reject = 'reject',
    Change = 'change',
  }

  export enum ContentDecisionCategoryLevelType {
    Green = 'green',
    Yellow = 'yellow',
    Red = 'red',
  }
  export enum ModerationProfileGroup {
    Common = 'common',
    Scam = 'scam',
    Unapproved = 'unapproved',
  }

  export type ContentDecisionCategoryLevel = {
    category: string;
    level: ContentDecisionCategoryLevelType;
  };

  export type ContentDecision = {
    categoryLevels?: ContentDecisionCategoryLevel[];
    content: string;
    contentId: number;
    contentSource?: ContentDecisionSource;
    contentType: ContentDecisionConentType;
    decision: ContentDecisionType;
    initiatedBy: string;
    timestamp: string;
  };

  export type ModerationContentDecisions = {
    decisions: ContentDecision[];
  };

  export type ModeratingContent = {
    categoryLevels: ContentDecisionCategoryLevel[];
    content: string;
    contentId: number;
    contentSource?: ContentDecisionSource;
    contentType: ContentDecisionConentType;
    initiatedBy: string;
  };

  export type GroupsDecisions = {
    initiatedBy: string;
    group: ModerationProfileGroup;
    reason?: string;
    timestamp: string;
  };

  export type ModerationGroupsDecisions = {
    decisions: GroupsDecisions[];
  };

  export type CurrentContents = {
    isProcessed: boolean;
  } & Pick<ModeratingContent, 'content' | 'contentType'> &
    Partial<Pick<ModeratingContent, 'contentId' | 'contentSource' | 'initiatedBy'>>;

  export type ModerationQueueNext = {
    userId: string;
    version?: number;
    lockSeconds: number;
    profile: {
      age: number;
      gender: string;
      thumbnail: string;
      group: ModerationProfileGroup;
    };
    moderatingContents: ModeratingContent[];
    currentContents: CurrentContents[];
  };

  export type ModerationQueueCount = {
    count: number;
  };

  export type ModerationProfilesCount = {
    moderatedProfiles: number;
  };

  export type ModerationResult = {
    version: number | null;
    actions: {
      name: string;
      value?: string | { group: string; reason: string };
    }[];
    userContents: { contentId: number; contentType: string; categories: string[] }[];
  };
}
