import React, { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import copy from 'copy-to-clipboard';
import { CircularProgress } from '@material-ui/core';

import { Resources } from 'src/resources';
import { AIBubble, Bubble, LabelText, LabelTextSize } from 'src/components/common/flure';
import {
  flureModerationPhotoSize,
  PhotoTransformation,
  useGetMediaSourceAuthed,
  useGetVerificationPhotoAuthed,
} from 'src/components/Moderation/utils/image-source';
import {
  AIDecisionForPhoto,
  AIDecisionForVerificationPhoto,
  AIDecisionPhotoType,
  PhotoHistoryItem,
} from 'src/network/flure-ai-decision/types';
import { formatAIDecisionText } from 'src/utils/format-ai-decision-text';

import { useStyles } from './styles';

type Props = {
  item: PhotoHistoryItem;
  photoType: AIDecisionPhotoType;
};

const emptyObject = {};

export const PhotoItem = React.memo(({ item, photoType }: Props) => {
  const isModerationPhoto =
    photoType === AIDecisionPhotoType.Moderation || photoType === AIDecisionPhotoType.ModerationExperiment;
  const classes = useStyles();
  const { aiDecision, basePath, baseName, mediaSource } = item || emptyObject;
  const userId = basePath?.split('/')[2] || '';
  const getVerificationPhoto = useGetVerificationPhotoAuthed();
  const getMediaSource = useGetMediaSourceAuthed();

  const photoSrc = useMemo(() => {
    if (!basePath || !baseName) {
      return '';
    }

    if (isModerationPhoto) {
      return getMediaSource(
        userId,
        baseName,
        'image/*',
        true,
        flureModerationPhotoSize,
        PhotoTransformation.KeepAspectRatio,
      );
    }

    return getVerificationPhoto(basePath, baseName);
  }, [baseName, basePath, getMediaSource, getVerificationPhoto, isModerationPhoto, userId]);

  const { enqueueSnackbar } = useSnackbar();

  const copyUserId = () => {
    copy(userId);
    enqueueSnackbar(Resources.strings.moderation.userIdCopiedAlert, { variant: 'success' });
  };

  return (
    <div className={classes.container}>
      <CircularProgress className={classes.loader} />
      {photoSrc && <img src={photoSrc} alt={baseName} className={classes.verificationImage} />}
      <AIBubble
        className={classes.aiBubble}
        isRecognized={
          isModerationPhoto
            ? (aiDecision as AIDecisionForPhoto)?.approved
            : (aiDecision as AIDecisionForVerificationPhoto)?.isRecognized
        }
        isSpicy={isModerationPhoto && (aiDecision as AIDecisionForPhoto).spicy}
        reason={isModerationPhoto ? formatAIDecisionText(aiDecision as AIDecisionForPhoto) : undefined}
      />
      <div className={classes.bottomBubbles}>
        {userId && (
          <Bubble>
            <button type="button" className={classes.button} onClick={copyUserId}>
              <LabelText
                className={classes.whiteTextUnderlined}
                text={`ID ${userId}`}
                size={LabelTextSize.ExtraSmall}
              />
            </button>
          </Bubble>
        )}
        {mediaSource && (
          <Bubble>
            <LabelText className={classes.whiteTextUnderlined} text={mediaSource} size={LabelTextSize.ExtraSmall} />
          </Bubble>
        )}
      </div>
    </div>
  );
});
