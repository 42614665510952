import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  container: {
    paddingTop: 30,
    display: 'flex',
    flexDirection: 'column',
  },

  titleText: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '36px',
    fontWeight: 700,
    marginBottom: '16px',
  },

  cardsContainer: {
    padding: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: '16px',
    borderRadius: '12px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05), inset 0px 0px 1px 0px #EDEDED',
  },
});
