import React, { useEffect, useRef, useState } from 'react';

import { Typography } from '@material-ui/core';

import { ONCE } from 'src/types/once';
import { mapCategoryLevels } from 'src/components/Moderation/screens/UserModeration/once/utils';

import { ModerationContentDialog } from '../../moderation-content-dialog';

import { useStyles, textMaxLines, textLineHeight } from './styles';
import overflowingIcon from './overflowing.svg';

type Props = {
  decision: ONCE.ContentDecision;
};

export const OnceModerationContentCardText = (props: Props) => {
  const { decision } = props;
  const { content, contentType, categoryLevels = [] } = decision;

  const classes = useStyles();

  const textRef = useRef<HTMLSpanElement>(null);

  const [isOverflowing, setIsOverflowing] = useState(false);

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [popoverAnchorEl, setAnchorEl] = useState<Element>();

  const handleCardClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isOverflowing) {
      setDialogOpen(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    setDialogOpen(false);
  };

  useEffect(() => {
    if (!textRef.current) return;

    const elementHeight = textRef.current.scrollHeight;
    const maxAllowedHeight = textMaxLines * textLineHeight;

    setIsOverflowing(elementHeight > maxAllowedHeight);
  }, [content]);

  return (
    <>
      <button type="button" className={classes.container} onClick={handleCardClick}>
        <Typography ref={textRef} className={classes.text}>
          {content}
        </Typography>

        {isOverflowing && (
          <div className={classes.overflowingContainer}>
            <img src={overflowingIcon} alt="overflowing ID" />
          </div>
        )}
      </button>

      <ModerationContentDialog
        contentType={contentType}
        content={content}
        handleClose={handleClose}
        isOpen={isDialogOpen}
        popoverAnchorEl={popoverAnchorEl}
        categoryLevels={mapCategoryLevels(categoryLevels)}
      />
    </>
  );
};
