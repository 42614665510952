import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() => {
  return {
    backButton: {
      position: 'sticky',
      left: '24px',
      top: '24px',
      backgroundColor: '#FFFFFF',
      height: '64px',
      minHeight: '64px',
      width: '64px',
      borderRadius: '12px',
      border: '1px solid #EDEDED',
    },

    contentContainer: {
      marginTop: 16,
      marginBottom: 16,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '1096px',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  };
});
