import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';
import { Button, MenuItem } from '@material-ui/core';
import { useAuth } from 'src/services/auth';
import { getUserRolesMap } from 'src/components/config';

import { MenuButton } from '../menu-button';

export const FlureMenu = () => {
  const { me } = useAuth();
  const rolesMap = useMemo(() => getUserRolesMap(me?.roles || []), [me]);
  const { isModerationAdministrator, isOperator, isCustomerSupport, isChatOperator, isPromoCodesOperator } = rolesMap;

  const isOperatorOrAdmin = isOperator || isModerationAdministrator;
  const renderModerationSection = isOperatorOrAdmin || isCustomerSupport;

  return (
    <>
      {isPromoCodesOperator && (
        <Button to="/local-events" component={Link} color="inherit">
          Local Events
        </Button>
      )}
      {isModerationAdministrator && (
        <Button to="/vibes-and-premium-management" component={Link} color="inherit">
          Vibes & Premium Management
        </Button>
      )}
      {isOperatorOrAdmin && (
        <MenuButton keyId="menu-verification" type="text" title="Verification">
          <MenuItem to="/verification" component={Link}>
            Verification
          </MenuItem>
          <MenuItem to="/verification-history" component={Link}>
            Photo History
          </MenuItem>
        </MenuButton>
      )}
      {renderModerationSection && (
        <MenuButton keyId="menu-moderation" type="text" title="Moderation">
          <MenuItem to="/moderation" component={Link}>
            Moderation
          </MenuItem>
          <MenuItem to="/moderation-history" component={Link}>
            Photo History
          </MenuItem>
        </MenuButton>
      )}
      {isOperatorOrAdmin && (
        <Button to="/waiting-list" component={Link} color="inherit">
          Waiting List
        </Button>
      )}
      {isOperatorOrAdmin && (
        <Button to="/flure-feed" component={Link} color="inherit">
          Feed Preview
        </Button>
      )}
      {isChatOperator && (
        <Button to="/flure-chat-moderation" component={Link} color="inherit">
          Chat Moderation
        </Button>
      )}
      {isChatOperator && (
        <Button to="/flure-chat" component={Link} color="inherit">
          Flure Bunny
        </Button>
      )}
    </>
  );
};
