import React from 'react';

import { ONCE } from 'src/types/once';
import { Gender } from 'src/types/user';
import { ModerationContent, ModerationProfileData } from 'src/components/Moderation/screens/UserModeration/once/utils';

import { useStyles } from './styles';
import { ModerationProfileInfoGenderButton } from './gender-button';
import { ModerationProfileInfoText } from './text';

type Props = {
  profileData: ModerationProfileData;
  moderationContent: ModerationContent;
  setModerationContent: (
    field: keyof ModerationContent,
    category: string,
    level: ONCE.ContentDecisionCategoryLevelType,
  ) => void;
  toggleUserGender: () => void;
  profileGender?: Gender;
};

export const ModerationProfileInfo = (props: Props) => {
  const { profileData, setModerationContent, moderationContent, toggleUserGender, profileGender } = props;

  const classes = useStyles();

  const { newName, newOccupation, newAbout } = moderationContent;

  const { age } = profileData;
  const endingPart = Number(age) > 0 ? `, ${age}` : '';

  return (
    <div className={classes.container}>
      <div className={classes.genderContainer}>
        <ModerationProfileInfoGenderButton gender={profileGender} toggleUserGender={toggleUserGender} />

        {newName?.content && (
          <div className={classes.nameContainer}>
            <ModerationProfileInfoText
              field="newName"
              moderationContent={newName}
              bold
              setModerationContent={setModerationContent}
              contentFormatter={(content) => `${content}${endingPart}`}
            />
          </div>
        )}
      </div>

      {newAbout?.content && (
        <ModerationProfileInfoText
          field="newAbout"
          setModerationContent={setModerationContent}
          moderationContent={newAbout}
          mt={8}
        />
      )}

      {newOccupation?.content && (
        <ModerationProfileInfoText
          field="newOccupation"
          setModerationContent={setModerationContent}
          moderationContent={newOccupation}
          mt={8}
        />
      )}
    </div>
  );
};
