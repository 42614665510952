import React from 'react';
import { Dialog, DialogContent, IconButton, Popover, PopoverProps } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CloseIcon } from '@material-ui/data-grid';

import { ONCE } from 'src/types/once';
import {
  useModerationContentCategoriesPhoto,
  useModerationContentCategoriesText,
} from 'src/components/Moderation/hooks/once';
import { userCachedPhotoSize } from 'src/components/Moderation/utils/image-source';
import { ModerationContentItem } from 'src/components/Moderation/screens/UserModeration/once/utils';

import { ModerationContentDialogReadonlyPlaceholder } from './readonly-placeholder';
import { ModerationContentCategories } from './categories';
import { useStyles } from './styles';
import { ModerationContentImageWithPlaceholder } from '../moderation-content/card-photo/image-with-placeholder';

const selectedCategoriesFallback: Record<string, ONCE.ContentDecisionCategoryLevelType> = {};
const anchorOrigin: PopoverProps['anchorOrigin'] = { vertical: 'center', horizontal: 'center' };
const transformOrigin: PopoverProps['transformOrigin'] = { vertical: 'center', horizontal: 'left' };

type Props = {
  categoryLevels?: ModerationContentItem['categoryLevels'];
  content: ModerationContentItem['content'];
  contentType: ModerationContentItem['contentType'];
  handleClose: () => void;
  isOpen: boolean;
  popoverAnchorEl?: Element;
  popoverAnchorOrigin?: PopoverProps['anchorOrigin'];
  popoverTransformOrigin?: PopoverProps['transformOrigin'];
  setCategoryLevel?: (category: string, level: ONCE.ContentDecisionCategoryLevelType) => void;
  isReadonly?: boolean;
};

export const ModerationContentDialog = (props: Props) => {
  const {
    categoryLevels,
    content,
    contentType,
    handleClose,
    isOpen,
    popoverAnchorEl,
    popoverAnchorOrigin = anchorOrigin,
    popoverTransformOrigin = transformOrigin,
    setCategoryLevel,
    isReadonly = false,
  } = props;

  const isPhoto = contentType === ONCE.ContentDecisionConentType.Photo;

  const classes = useStyles();

  const {
    categories: allCategoriesText,
    categoriesToModerate: categoriesToModerateText,
  } = useModerationContentCategoriesText();
  const categoriesText = setCategoryLevel ? categoriesToModerateText : allCategoriesText;
  const {
    categories: allCategoriesPhoto,
    categoriesToModerate: categoriesToModeratePhoto,
  } = useModerationContentCategoriesPhoto();
  // TODO: change condition after chatgpt is ready
  const categoriesPhoto = isPhoto ? categoriesToModeratePhoto : allCategoriesPhoto;

  const categories = isPhoto ? categoriesPhoto : categoriesText;

  const isPopoverOpen = Boolean(popoverAnchorEl);
  const popoverId = isPopoverOpen ? 'simple-popover' : undefined;

  const selectedCategories = categoryLevels || selectedCategoriesFallback;

  return (
    <>
      <Dialog fullScreen open={isOpen} onClose={handleClose} PaperProps={{ className: classes.modalContainer }}>
        <DialogContent
          className={classes.modalContentContainer}
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              handleClose();
            }
          }}
        >
          <div className={classes.modalLeftContainer}>
            {isPhoto ? (
              <ModerationContentImageWithPlaceholder
                source={content?.replace(userCachedPhotoSize, '')}
                className={classes.contentPhoto}
                fallbackClasses={classes.contentFallbackPhoto}
              />
            ) : (
              <div className={classes.textContainer}>
                <Typography className={classes.contentText}>{content}</Typography>
              </div>
            )}
          </div>
          <div className={classes.modalRightContainer}>
            {isReadonly ? (
              <ModerationContentDialogReadonlyPlaceholder />
            ) : (
              <ModerationContentCategories
                selectedCategories={selectedCategories}
                categories={categories}
                wrapItems={isPhoto}
                setCategoryLevel={setCategoryLevel}
              />
            )}
          </div>

          <IconButton aria-label="close" onClick={handleClose} className={classes.modalIconContainer}>
            <CloseIcon className={classes.modalIcon} />
          </IconButton>
        </DialogContent>
      </Dialog>

      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
        anchorOrigin={popoverAnchorOrigin}
        transformOrigin={popoverTransformOrigin}
        PaperProps={{ className: classes.popupContainer }}
      >
        <div className={classes.popupContentContainer}>
          <ModerationContentCategories
            selectedCategories={selectedCategories}
            categories={categories}
            setCategoryLevel={setCategoryLevel}
          />
          <IconButton aria-label="close" onClick={handleClose} className={classes.popupIconContainer}>
            <CloseIcon className={classes.popupIcon} />
          </IconButton>
        </div>
      </Popover>
    </>
  );
};
