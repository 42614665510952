import React, { useRef, useCallback, useMemo } from 'react';
import clsx from 'clsx';

import { LabelText, LabelTextType } from 'src/components/common/flure';
import { MediaType } from 'src/types/user';

import trashCanIcon from './trash-can-icon.png';
import { useStyles } from './styles';

export enum MediaUploadInputSize {
  Small = 'small',
  Large = 'large',
}

const MAX_FILE_SIZE = 100 * 1024 * 1024;

type Props = {
  value?: string;
  size?: MediaUploadInputSize;
  onChange?: (source: string | undefined, file?: File, mediaType?: MediaType) => void;
  className?: string;
  placeholder?: string;
  placeholderClassName?: string;
  mediaPreview: string | undefined;
  setMediaPreview: React.Dispatch<React.SetStateAction<string | undefined>>;
  mediaType?: MediaType | undefined;
  setMediaType?: React.Dispatch<React.SetStateAction<MediaType | undefined>>;
  setVideoDuration?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const MediaUploadInputView: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    className,
    placeholder,
    size = MediaUploadInputSize.Small,
    placeholderClassName,
    onChange,
    mediaPreview,
    setMediaPreview,
    mediaType,
    setMediaType,
    setVideoDuration,
  } = props;
  const filePickerRef = useRef(null);
  const isVideo = mediaType === MediaType.Video;

  const { inputClassName, removeButtonClassName } = useMemo(() => {
    const inputStyle = [classes.buttonInput];
    const removeButtonStyle = [classes.removeImageButton];

    if (size === MediaUploadInputSize.Small) {
      inputStyle.push(classes.smallInput);
      removeButtonStyle.push(classes.smallInput);
    }

    if (size === MediaUploadInputSize.Large) {
      inputStyle.push(classes.largeInput);
      removeButtonStyle.push(classes.largeInput);
    }

    return { inputClassName: clsx(...inputStyle, className), removeButtonClassName: clsx(...removeButtonStyle) };
  }, [className, classes.buttonInput, classes.largeInput, classes.removeImageButton, classes.smallInput, size]);

  const previewFile = useCallback(
    (e) => {
      const reader = new FileReader();

      const selectedFile = e.target.files[0];

      if (selectedFile.size > MAX_FILE_SIZE) {
        // eslint-disable-next-line no-alert
        alert('Размер файла не должен превышать 100 MB.');
        return;
      }

      if (selectedFile) {
        reader.readAsDataURL(selectedFile);
      }

      reader.onload = (readerEvent) => {
        if (readerEvent.target) {
          const type = selectedFile.type?.startsWith('video') ? MediaType.Video : MediaType.Photo;
          setMediaPreview(readerEvent.target.result as string);
          setMediaType?.(type);

          const media = new Audio(readerEvent.target.result as string);
          media.onloadedmetadata = () => setVideoDuration?.(media.duration * 1000);

          if (onChange) {
            onChange(readerEvent.target.result as string, selectedFile, type);
          }
        }
      };
    },
    [onChange, setMediaPreview, setMediaType, setVideoDuration],
  );

  const removeImage = useCallback(() => {
    setMediaPreview(undefined);
    setMediaType?.(undefined);
    setVideoDuration?.(undefined);

    if (onChange) {
      onChange(undefined, undefined);
    }
  }, [onChange, setMediaPreview, setMediaType, setVideoDuration]);

  return (
    <div className={classes.container}>
      <input ref={filePickerRef} accept="image/*,video/mp4" onChange={previewFile} type="file" hidden />
      {!isVideo && (
        <input
          type="image"
          className={inputClassName}
          placeholder="Img"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClick={() => filePickerRef.current?.click()}
          alt=""
          src={mediaPreview}
        />
      )}
      {isVideo && mediaPreview && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video className={inputClassName}>
          <source src={mediaPreview} />
          Your browser does not support HTML5 video.
        </video>
      )}
      {!mediaPreview && (
        <LabelText
          className={clsx(classes.placeholderText, placeholderClassName)}
          text={placeholder || 'Photo/Video'}
          type={LabelTextType.Info}
        />
      )}
      {!!mediaPreview && (
        <button className={removeButtonClassName} type="button" onClick={removeImage}>
          <img className={classes.trashIcon} src={trashCanIcon} alt="Remove" />
        </button>
      )}
    </div>
  );
};

export const MediaUploadInput = React.memo(MediaUploadInputView);
