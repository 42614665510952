import React, { useCallback, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import { Resources } from 'src/resources';
import { TitleText, TitleTextSize } from 'src/components/common/flure';
import { MediaUploadInput, MediaUploadInputSize, PhonePreview, TextInput } from 'src/components/FlureChat/components';
import { MediaType } from 'src/types/user';
import { createComponentsArray } from 'src/components/FlureChat/utils';

import { useStyles } from './styles';
import { FieldWrapper } from '../field-wrapper';
import { ButtonWithAction } from '../button-with-action';
import { TextStylingHints } from '../text-styling-hints';
import { MessageComponents } from '../../types';

type Props = {
  messageTextInitialValue?: string;
  messageText: string | undefined;
  setMessageText: React.Dispatch<React.SetStateAction<string | undefined>>;
  messageMedia: string | undefined;
  setMessageMedia: React.Dispatch<React.SetStateAction<string | undefined>>;
  setMessageMediaFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  messageMediaPreview: string | undefined;
  setMessageMediaPreview: React.Dispatch<React.SetStateAction<string | undefined>>;
  mediaType: MediaType | undefined;
  setMediaType: React.Dispatch<React.SetStateAction<MediaType | undefined>>;
  setVideoDuration?: React.Dispatch<React.SetStateAction<number | undefined>>;
  buttonTitle: string;
  setButtonTitle: React.Dispatch<React.SetStateAction<string>>;
  selectedButtonUrl: string;
  setSelectedButtonUrl: React.Dispatch<React.SetStateAction<string>>;
  messageComponents: MessageComponents[];
  setMessageComponents: React.Dispatch<React.SetStateAction<MessageComponents[]>>;
  messageComponentEnabled: Record<MessageComponents, boolean>;
  setMessageComponentEnabled: React.Dispatch<React.SetStateAction<Record<MessageComponents, boolean>>>;
};

const reorder = (list: MessageComponents[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  background: isDragging ? '#F4F4F4' : 'white',
  borderRadius: '16px',
  margin: '0 0 24px 0',
  ...draggableStyle,
});

const MessageWithPreviewView: React.FC<Props> = (props) => {
  const {
    messageTextInitialValue,
    messageText,
    setMessageText,
    messageMedia,
    setMessageMedia,
    setMessageMediaFile,
    messageMediaPreview,
    setMessageMediaPreview,
    mediaType,
    setMediaType,
    setVideoDuration,
    selectedButtonUrl,
    setSelectedButtonUrl,
    buttonTitle,
    setButtonTitle,
    messageComponents,
    setMessageComponents,
    messageComponentEnabled,
    setMessageComponentEnabled,
  } = props;
  const classes = useStyles();
  const isVideoMedia = mediaType === MediaType.Video;
  const messageComponentsWithOneMedia = useMemo(
    () =>
      messageComponents.filter((it) =>
        isVideoMedia ? it !== MessageComponents.Photo : it !== MessageComponents.Video,
      ),
    [isVideoMedia, messageComponents],
  );
  const enabledMessageComponents = messageComponents.filter((it) => messageComponentEnabled[it]);  

  const setTextComponentEnabled = useCallback(
    (enabled: boolean) => {
      setMessageComponentEnabled({
        ...messageComponentEnabled,
        [MessageComponents.Text]: enabled,
      });
    },
    [messageComponentEnabled, setMessageComponentEnabled],
  );

  const setPhotoComponentEnabled = useCallback(
    (enabled: boolean, isVideo?: boolean) => {
      setMessageComponentEnabled({
        ...messageComponentEnabled,
        [isVideo || isVideoMedia ? MessageComponents.Video : MessageComponents.Photo]: enabled,
      });
    },
    [isVideoMedia, messageComponentEnabled, setMessageComponentEnabled],
  );

  const setButtonComponentEnabled = useCallback(
    (enabled: boolean) => {
      setMessageComponentEnabled({
        ...messageComponentEnabled,
        [MessageComponents.Button]: enabled,
      });
    },
    [messageComponentEnabled, setMessageComponentEnabled],
  );

  const onMessageTextChange = useCallback(
    (event) => {
      setMessageText(event.target.value);
      setTextComponentEnabled(!!event.target.value);
    },
    [setMessageText, setTextComponentEnabled],
  );

  const onMessageMediaChange = useCallback(
    (source: string | undefined, file?: File, type?: MediaType) => {
      setMessageMedia(source);
      setMessageMediaFile(file);
      setPhotoComponentEnabled(!!source, type && type === MediaType.Video);
    },
    [setMessageMedia, setMessageMediaFile, setPhotoComponentEnabled],
  );

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      const items = reorder(messageComponentsWithOneMedia, result.source.index, result.destination.index);
      const allComponents = createComponentsArray(items);
      setMessageComponents(allComponents);
    },
    [messageComponentsWithOneMedia, setMessageComponents],
  );

  return (
    <div className={classes.messageBlockContainer}>
      <div className={classes.leftContainer}>
        <div className={classes.messageContentContainer}>
          <TitleText
            className={classes.subtitle}
            text={Resources.strings.flureChat.messageTitle}
            size={TitleTextSize.Medium}
          />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div className={classes.inputsContainer} ref={provided.innerRef} {...provided.droppableProps}>
                  {messageComponentsWithOneMedia.map((it, index) => {
                    return (
                      <Draggable key={it} draggableId={it} index={index}>
                        {(drProvided, drSnapshot) => (
                          <div
                            ref={drProvided.innerRef}
                            {...drProvided.draggableProps}
                            {...drProvided.dragHandleProps}
                            style={getItemStyle(drSnapshot.isDragging, drProvided.draggableProps.style)}
                          >
                            {it === MessageComponents.Text && (
                              <FieldWrapper
                                title={Resources.strings.flureChat.message}
                                enabled={messageComponentEnabled[MessageComponents.Text]}
                                setEnabled={setTextComponentEnabled}
                              >
                                <TextInput
                                  className={classes.messageInput}
                                  initialValue={messageTextInitialValue}
                                  value={messageText}
                                  placeholder={Resources.strings.flureChat.messageInputPlaceholder}
                                  onChange={onMessageTextChange}
                                  multiline
                                />
                              </FieldWrapper>
                            )}
                            {(it === MessageComponents.Photo || it === MessageComponents.Video) && (
                              <FieldWrapper
                                title={Resources.strings.flureChat.photoOrVideo}
                                enabled={messageComponentEnabled[it]}
                                setEnabled={setPhotoComponentEnabled}
                                switchDisabled={!messageMediaPreview}
                              >
                                <MediaUploadInput
                                  className={classes.messageInput}
                                  size={MediaUploadInputSize.Large}
                                  onChange={onMessageMediaChange}
                                  mediaPreview={messageMediaPreview}
                                  setMediaPreview={setMessageMediaPreview}
                                  mediaType={mediaType}
                                  setMediaType={setMediaType}
                                  setVideoDuration={setVideoDuration}
                                />
                              </FieldWrapper>
                            )}
                            {it === MessageComponents.Button && (
                              <FieldWrapper
                                title={Resources.strings.flureChat.button}
                                enabled={messageComponentEnabled[MessageComponents.Button]}
                                setEnabled={setButtonComponentEnabled}
                              >
                                <ButtonWithAction
                                  enabled={messageComponentEnabled[MessageComponents.Button]}
                                  buttonTitle={buttonTitle}
                                  setButtonTitle={setButtonTitle}
                                  selectedAction={selectedButtonUrl}
                                  setSelectedAction={setSelectedButtonUrl}
                                />
                              </FieldWrapper>
                            )}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>

      <div className={classes.rightContainer}>
        <PhonePreview
          messages={[
            {
              text: messageComponentEnabled[MessageComponents.Text] ? messageText : '',
              reference:
                messageComponentEnabled[MessageComponents.Photo] || messageComponentEnabled[MessageComponents.Video]
                  ? messageMedia
                  : '',
              meta: {
                buttonTitle: messageComponentEnabled[MessageComponents.Button] && selectedButtonUrl ? buttonTitle : '',
                componentsOrder: enabledMessageComponents,
              },
            },
          ]}
          withHeader
        />
        <TextStylingHints />
      </div>
    </div>
  );
};

export const MessageWithPreview = React.memo(MessageWithPreviewView);
