import { ONCE } from 'src/types/once';
import { Gender } from 'src/types/user';

import { ModerationContent, ModerationProfileData } from './types';
import { getModerationContentItem } from './get-moderation-content-item';

type ModerationProfile = {
  profileData: ModerationProfileData;
  moderationContent: ModerationContent;
};

type ModerationFieldMap = {
  [key in ONCE.ContentDecisionSource]: keyof Omit<ModerationContent, 'newMedia'>;
};

const FIELD_MAP: ModerationFieldMap = {
  [ONCE.ContentDecisionSource.Name]: 'newName',
  [ONCE.ContentDecisionSource.About]: 'newAbout',
  [ONCE.ContentDecisionSource.Occupation]: 'newOccupation',
};

export const mapQueueToModerationData = (moderationQueue: ONCE.ModerationQueueNext): ModerationProfile => {
  const { moderatingContents, currentContents, userId, profile } = moderationQueue;

  const processContent = (contents: (ONCE.ModeratingContent | ONCE.CurrentContents)[], content: ModerationContent) => {
    contents.forEach((curr) => {
      const item = getModerationContentItem(curr);

      if (curr.contentType === ONCE.ContentDecisionConentType.Photo) {
        content.newMedia = {
          ...content.newMedia,
          [curr.content]: item,
        };
      } else if (curr.contentType === ONCE.ContentDecisionConentType.Text && curr.contentSource) {
        const fieldKey = FIELD_MAP[curr.contentSource];
        if (fieldKey) {
          content[fieldKey] = item;
        }
      }
    });
  };

  const moderationContent: ModerationContent = {};
  processContent(currentContents, moderationContent);
  processContent(moderatingContents, moderationContent);

  return {
    moderationContent,
    profileData: {
      id: userId,
      gender: profile.gender === Gender.Female ? Gender.Female : Gender.Male,
      thumbnail: profile.thumbnail,
      age: profile.age,
      group: profile.group,
    },
  };
};
