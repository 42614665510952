import { AxiosResponse } from 'axios';
import { Http } from 'src/network/http';
import qs from 'qs';

import { ApiGetMessages, ApiUploadPhoto, ChatAuditoryParams, MessageBody, MessagesByIdsParams } from './types';

const sendMessageToSelectedAuditory = (operatorId: string, message: MessageBody) =>
  Http.shared().instance.post(`dialogs/messages/${operatorId}/flure/channel`, message);

const getMessagesForSelectedAuditory = (operatorId: string, params: ChatAuditoryParams) =>
  Http.shared()
    .instance.get<ApiGetMessages>(
      `dialogs/messages/${operatorId}/flure/channel?${qs.stringify(params, { arrayFormat: 'repeat' })}`,
    )
    .then((response: AxiosResponse<ApiGetMessages>) => response.data);

const getMessagesByIds = (operatorId: string, params: MessagesByIdsParams) =>
  Http.shared()
    .instance.get<ApiGetMessages>(
      `dialogs/messages/${operatorId}/flure/channel/list?${qs.stringify(params, { arrayFormat: 'repeat' })}`,
    )
    .then((response: AxiosResponse<ApiGetMessages>) => response.data);

const uploadPhotoToChat = (operatorId: string, file: FormData) =>
  Http.shared().instance.post<ApiUploadPhoto>(`channel/${operatorId}/photos/flure`, file, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

const uploadVideoToChat = (operatorId: string, file: FormData) =>
  Http.shared().instance.post<ApiUploadPhoto>(`channel/${operatorId}/videos/flure`, file, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

const getPhotoUri = (operatorId: string, baseName: string) =>
  `${Http.shared().apiHost}/channel/${operatorId}/photos/flure/${baseName}`;

const getVideoUri = (operatorId: string, baseName: string) =>
  `${Http.shared().apiHost}/channel/${operatorId}/videos/flure/${baseName}`;

const patchMessage = (operatorId: string, messageId: string, message: Omit<MessageBody, 'text' | 'reference'>) =>
  Http.shared().instance.patch(`dialogs/messages/${operatorId}/flure/channel/${messageId}`, message);

const deleteMessage = (operatorId: string, messageId: string) =>
  Http.shared().instance.delete(`dialogs/messages/${operatorId}/flure/channel/${messageId}`);

export const flureChatRequest = {
  uploadPhotoToChat,
  uploadVideoToChat,
  getPhotoUri,
  getVideoUri,
  sendMessageToSelectedAuditory,
  getMessagesForSelectedAuditory,
  getMessagesByIds,
  patchMessage,
  deleteMessage,
};
