import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { difference } from 'lodash';

import { MediaTag } from 'src/types/user';
import { Resources } from 'src/resources';
import { AIDecisionForPhoto } from 'src/network/flure-ai-decision/types';

import { useStyles } from './styles';

type Props = {
  userId: string;
  aiDecision?: AIDecisionForPhoto;
  tags: MediaTag[];
  baseName: string;
  setMediaTags: React.Dispatch<React.SetStateAction<Record<string, MediaTag[]>>>;
  hasSelectedThumbnail?: boolean;
};

export const TagsActionButtons = ({
  userId,
  aiDecision,
  tags = [],
  baseName,
  setMediaTags,
  hasSelectedThumbnail,
}: Props) => {
  const classes = useStyles();
  const { approved, spicy, suitableForProfilePic } = aiDecision || {};
  const aiDecisionHandled = useRef(false);
  const [spicyDisabled, setSpicyDisabled] = useState(approved === false);
  const [thumbnailDisabled, setThumbnailDisabled] = useState(approved === false);

  const isSpicyActive = tags.includes(MediaTag.Spicy);
  const isThumbnailActive = tags.includes(MediaTag.Thumbnail);

  const setOrRemoveTag = useCallback(
    (tag: MediaTag) => {
      let newTags = tags.includes(tag) ? tags.filter((it) => it !== tag) : [...tags, tag];

      if (tag === MediaTag.Spicy && tags.includes(MediaTag.Thumbnail)) {
        newTags = [...tags.filter((it) => it !== MediaTag.Thumbnail), MediaTag.Spicy];
      } else if (tag === MediaTag.Thumbnail && tags.includes(MediaTag.Spicy)) {
        newTags = [...tags.filter((it) => it !== MediaTag.Spicy), MediaTag.Thumbnail];
      }

      setMediaTags((currentState) => {
        const current = { ...currentState };

        if (tag === MediaTag.Thumbnail) {
          const currentThumbnailMedia = Object.keys(current).find((name) =>
            current[name]?.includes(MediaTag.Thumbnail),
          );

          if (currentThumbnailMedia) {
            current[currentThumbnailMedia] = current[currentThumbnailMedia].filter((it) => it !== MediaTag.Thumbnail);
          }
        }
        return {
          ...current,
          [baseName]: newTags,
        };
      });
    },
    [baseName, setMediaTags, tags],
  );

  const addTag = useCallback(
    (tag: MediaTag) => {
      const newTags = Array.from(new Set([...tags, tag]));

      setMediaTags((currentState) => {
        return {
          ...currentState,
          [baseName]: newTags,
        };
      });
    },
    [baseName, setMediaTags, tags],
  );

  const removeTags = useCallback(
    (tagsToReset: MediaTag[]) => {
      const newTags = difference(tags, tagsToReset);

      setMediaTags((currentState) => {
        return {
          ...currentState,
          [baseName]: newTags,
        };
      });
    },
    [baseName, setMediaTags, tags],
  );

  useEffect(() => {
    if (approved !== undefined && !aiDecisionHandled.current) {
      aiDecisionHandled.current = true;

      if (!approved) {
        removeTags([MediaTag.Spicy, MediaTag.Thumbnail]);
        setSpicyDisabled(true);
        setThumbnailDisabled(true);
      } else if (spicy && !isSpicyActive) {
        addTag(MediaTag.Spicy);
        setThumbnailDisabled(true);
      } else if (suitableForProfilePic && !hasSelectedThumbnail) {
        addTag(MediaTag.Thumbnail);
      }
    }
  }, [
    addTag,
    approved,
    hasSelectedThumbnail,
    isSpicyActive,
    isThumbnailActive,
    removeTags,
    setOrRemoveTag,
    spicy,
    suitableForProfilePic,
  ]);

  useEffect(() => {
    if (userId) {
      aiDecisionHandled.current = false;
    }
  }, [userId]);

  return (
    <div className={classes.buttonsContainer}>
      <button
        type="button"
        className={clsx(
          classes.tagButton,
          classes.leftTagButton,
          isThumbnailActive && classes.activeThumbnail,
          thumbnailDisabled && classes.disabled,
        )}
        onClick={() => {
          setOrRemoveTag(MediaTag.Thumbnail);
        }}
        disabled={thumbnailDisabled}
      >
        {Resources.strings.moderation.media.thumbnail}
      </button>
      <button
        type="button"
        className={clsx(
          classes.tagButton,
          classes.rightTagButton,
          isSpicyActive && classes.activeSpicy,
          spicyDisabled && classes.disabled,
        )}
        onClick={() => {
          setOrRemoveTag(MediaTag.Spicy);
        }}
        disabled={spicyDisabled}
      >
        {Resources.strings.moderation.media.spicy}
      </button>
    </div>
  );
};
