import React, { useRef } from 'react';

import { ModerationProfileData } from 'src/components/Moderation/screens/UserModeration/once/utils';
import { OnceModerationContent } from 'src/components/Moderation/screens/UserModeration/once/components/moderation-content';
import { useDataContext, useActionsContext } from 'src/components/Moderation/screens/UserModeration/once/hooks';

import { ModerationProfileLabels } from './profile-labels';
import { ModerationProfileInfo } from './profile-info';
import { ModerationProfileMedia } from './profile-media';

import { useStyles } from './styles';
import { ModerationProfileHistoryButton } from './history-button';

type Props = {
  profileData: ModerationProfileData;
};

export const ModerationProfileContent = (props: Props) => {
  const { profileData } = props;

  const classes = useStyles();

  const {
    contentToModerate,
    isAdministrator,
    profileGender,
    profileGroup,
    profileMedia,
    profileThumbnail,
    rejectedDecisions,
  } = useDataContext();
  const { setModerationMode, setModerationContent, setProfileThumbnail, toggleUserGender } = useActionsContext();

  const stopWordRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.profileContainer}>
          <ModerationProfileLabels
            rejectedDecisions={rejectedDecisions}
            profileGroup={profileGroup}
            scrollRef={stopWordRef}
            setModerationMode={setModerationMode}
            userId={profileData.id}
          />
          <ModerationProfileInfo
            moderationContent={contentToModerate}
            setModerationContent={setModerationContent}
            toggleUserGender={toggleUserGender}
            profileData={profileData}
            profileGender={profileGender}
          />

          {isAdministrator && <ModerationProfileHistoryButton setModerationMode={setModerationMode} />}
        </div>

        <ModerationProfileMedia
          isAdministrator={isAdministrator}
          setModerationContent={setModerationContent}
          setProfileThumbnail={setProfileThumbnail}
          userId={profileData.id}
          profileMedia={profileMedia}
          profileThumbnail={profileThumbnail}
        />
      </div>

      <OnceModerationContent
        contentDecision={rejectedDecisions}
        ref={stopWordRef}
        isAdministrator={isAdministrator}
        userId={profileData.id}
      />
    </>
  );
};
