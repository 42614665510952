import React from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';

import { useStyles } from './styles';

type Props = {
  errorCode: number | null;
  isAdministrator: boolean;
  isModerationByUserId: boolean;
  loadNextProfile: () => void;
  userIdParams?: string;
};

export const ModerationProfilePlaceholder = (props: Props) => {
  const { isAdministrator, isModerationByUserId, loadNextProfile, userIdParams, errorCode } = props;

  const classes = useStyles();

  return (
    <div className={classes.placeholderContainer}>
      {isAdministrator && isModerationByUserId ? (
        <div>
          {errorCode === 409 ? (
            <>
              <div className={classes.tryAgainTitle}>👨🏻‍💻</div>
              <div className={classes.notFoundSubtitle}>{`User ${userIdParams} is being edited right now`}</div>
              <div className={clsx(classes.notFoundSubtitle, classes.tryAgainSubtitle)}>Try again later!</div>
            </>
          ) : (
            <>
              <div className={classes.notFoundTitle}>🔍</div>
              <div className={classes.notFoundSubtitle}>{`User ${userIdParams} not found`}</div>
            </>
          )}
        </div>
      ) : (
        <div>
          <div className={classes.placeholderTitle}>Awesome!</div>
          <div className={classes.placeholderSubtitle}>All users have been moderated</div>
          <Button className={classes.button} onClick={() => loadNextProfile()}>
            Refresh
          </Button>
        </div>
      )}
    </div>
  );
};
