import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() => {
  return {
    container: {
      position: 'absolute',
      top: '8px',
      left: '8px',
      padding: '4px 12px',
      borderRadius: '8px',
      backgroundColor: '#F31D1D80',
    },

    title: {
      color: '#FFFFFF',
      fontFamily: 'Poppins',
      fontSize: '10px',
      lineHeight: '15px',
    },
  };
});
