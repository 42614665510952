import { useCallback, useEffect, useMemo, useState } from 'react';

import { Gender } from 'src/types/user';
import { ONCE } from 'src/types/once';

import { ModerationProfileData } from '../utils';

export const useActionsToModerate = (user: ModerationProfileData | null) => {
  const [profileGender, setGender] = useState<Gender | undefined>();
  const [profileThumbnail, setThumbnail] = useState<string | undefined>();
  const [profileGroup, setGroup] = useState<ONCE.ModerationProfileGroup>(ONCE.ModerationProfileGroup.Common);
  const [profileGroupReason, setProfileGroupReason] = useState<string>('');

  const initialProfileData = useMemo(
    () => ({
      profileGender: user?.gender,
      profileGroup: user?.group || ONCE.ModerationProfileGroup.Common,
      profileGroupReason: '',
      profileThumbnail: user?.thumbnail,
    }),
    [user?.gender, user?.group, user?.thumbnail],
  );

  const resetProfileGroup = useCallback(() => {
    setGroup(initialProfileData.profileGroup);
    setProfileGroupReason(initialProfileData.profileGroupReason);
  }, [initialProfileData.profileGroup, initialProfileData.profileGroupReason]);

  const resetActions = useCallback(() => {
    setGender(initialProfileData.profileGender);
    setThumbnail(initialProfileData.profileThumbnail);
    resetProfileGroup();
  }, [initialProfileData.profileGender, initialProfileData.profileThumbnail, resetProfileGroup]);

  const toggleUserGender = useCallback(() => {
    setGender((prevGender) => {
      return prevGender === Gender.Female ? Gender.Male : Gender.Female;
    });
  }, []);

  const setProfileThumbnail = useCallback((basename: string) => {
    setThumbnail(basename);
  }, []);

  const setProfileGroup = useCallback((newGroup: ONCE.ModerationProfileGroup, reason: string) => {
    setGroup(newGroup);
    setProfileGroupReason(reason);
  }, []);

  useEffect(() => {
    resetActions();
  }, [resetActions]);

  return {
    initialProfileData,
    profileGender,
    profileGroup,
    profileGroupReason,
    profileThumbnail,
    resetActions,
    resetProfileGroup,
    setProfileGroup,
    setProfileThumbnail,
    toggleUserGender,
  };
};
