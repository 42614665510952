import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

import photoFallback from './photo-fallback.png';

type Props = {
  source: string;
  className?: string;
  fallbackClasses?: string;
};

export const ModerationContentImageWithPlaceholder = React.memo((props: Props) => {
  const { source, className, fallbackClasses } = props;

  const classes = useStyles();

  const statusRef = useRef<number | null>(null);

  const [hasError, setHasError] = useState<boolean | null>(null);

  useEffect(() => {
    fetch(source, { method: 'HEAD' })
      .then((response) => {
        statusRef.current = response.status;
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      });
  }, [source]);

  const hasDeleteError = statusRef.current === 410;

  if (hasError === null) {
    return null;
  }

  return (
    <>
      {hasError ? (
        <>
          <img src={photoFallback} alt="" className={clsx(classes.fallbackPhoto, fallbackClasses)} />
          {hasDeleteError && <p className={classes.fallbackText}>Photo has been removed from the system</p>}
        </>
      ) : (
        <img src={source} alt="" onError={() => setHasError(true)} className={clsx(classes.photo, className)} />
      )}
    </>
  );
});
