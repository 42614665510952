import React from 'react';

import { ONCE } from 'src/types/once';
import { ModerationMode } from 'src/components/Moderation/screens/UserModeration/once/provider/types';

import { ModerationProfileTag } from '../profile-tag';

import { useStyles } from './styles';

export const mapProfileGroupToText = {
  [ONCE.ModerationProfileGroup.Common]: 'Normal',
  [ONCE.ModerationProfileGroup.Unapproved]: 'Animators Paradise',
  [ONCE.ModerationProfileGroup.Scam]: 'Scam',
};


type Props = {
  profileGroup: ONCE.ModerationProfileGroup;
  rejectedDecisions: ONCE.ContentDecision[];
  scrollRef: React.RefObject<HTMLDivElement>;
  setModerationMode: (mode: ModerationMode | null) => void;
  userId: string;
};

export const ModerationProfileLabels = (props: Props) => {
  const { rejectedDecisions, profileGroup, scrollRef, userId, setModerationMode } = props;

  const classes = useStyles();

  const scrollToBlock = () => {
    scrollRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const onProfileGroupClick = () => {
    setModerationMode(ModerationMode.ChangeGroup);
  };

  const onProfileIdClick = () => {
    navigator.clipboard.writeText(userId);
  };

  return (
    <div className={classes.container}>
      <ModerationProfileTag
        label={mapProfileGroupToText[profileGroup]}
        {...(profileGroup === ONCE.ModerationProfileGroup.Unapproved && {
          containerClassname: classes.unapprovedContainer,
          labelClassname: classes.unapprovedText,
        })}
        {...(profileGroup === ONCE.ModerationProfileGroup.Scam && {
          containerClassname: classes.scamContainer,
          labelClassname: classes.scamText,
        })}
        tooltip="Click to change user group"
        onClick={onProfileGroupClick}
      />
      <ModerationProfileTag label={`ID ${userId}`} tooltip="Click to copy user ID" onClick={onProfileIdClick} />

      {rejectedDecisions.length > 0 && (
        <ModerationProfileTag
          label={`🚫 ${rejectedDecisions.length}`}
          tooltip="Deleted content"
          onClick={scrollToBlock}
        />
      )}
    </div>
  );
};
