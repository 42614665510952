import { createContext } from 'react';

import { ONCE } from 'src/types/once';

import { DataContextType, ActionsContextType } from './types';

export const DataContext = createContext<DataContextType>({
  contentToModerate: {},
  decisionsHistory: [],
  errorCode: null,
  groupsDecisions: [],
  initialProfileGroup: ONCE.ModerationProfileGroup.Common,
  isAdministrator: false,
  isModerationByUserId: false,
  isQueueLoading: false,
  moderationMode: null,
  processing: false,
  profileData: null,
  profileGroup: ONCE.ModerationProfileGroup.Common,
  profileGroupReason: '',
  profileMedia: [],
  profileThumbnail: undefined,
  profilesCount: 0,
  queueCount: 0,
  rejectedDecisions: [],
  resetDisabled: false,
});

const noop = () => {
  /* empty */
};

export const ActionsContext = createContext<ActionsContextType>({
  loadDecisionsHistory: noop,
  loadGroupsDecisions: noop,
  loadNextProfile: noop,
  onDelete: noop,
  onReset: noop,
  resetProfileGroup: noop,
  onSave: noop,
  setModerationContent: noop,
  setModerationMode: noop,
  setProfileGroup: noop,
  setProfileThumbnail: noop,
  toggleUserGender: noop,
});
