import React from 'react';
import { Button } from '@material-ui/core';

import { ModerationMode } from 'src/components/Moderation/screens/UserModeration/once/provider/types';

import { useStyles } from './styles';

type Props = {
  setModerationMode: (mode: ModerationMode | null) => void;
};

export const ModerationProfileHistoryButton = (props: Props) => {
  const { setModerationMode } = props;

  const classes = useStyles();

  const onClick = () => {
    setModerationMode(ModerationMode.History);
  };

  return (
    <Button variant="outlined" onClick={onClick} className={classes.button}>
      History
    </Button>
  );
};
