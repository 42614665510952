import { ONCE } from 'src/types/once';

const increaseGroupReasons = {
  'adult-perfomer': 'Аdult perfomer',
  'content-selling': 'Content selling',
  'different-websites': 'People from different websites under different names',
  'different-people': 'Photos of different people',
  'prostitution-escort': 'Prostitution, escort, sex trafficking',
  scammer: 'Scammer on photo',
  'spam-with-profiles': 'Spam with profiles',
  'name-differs': 'The name differs when searching by image',
};
const increaseGroupReasonsList = Object.keys(increaseGroupReasons).map((key) => {
  return { id: key, label: increaseGroupReasons[key as keyof typeof increaseGroupReasons] };
});

const decreaseGroupReasons = {
  'ai-mistake': 'AI mistake',
  'moderator-mistake': 'Moderator mistake',
  'moderator-miss-click': 'Moderator miss click',
  'moderator-hypervigilance': 'Moderator overvigilance',
  'by-request': 'By request',
};
const decreaseGroupReasonsList = Object.keys(decreaseGroupReasons).map((key) => {
  return { id: key, label: decreaseGroupReasons[key as keyof typeof decreaseGroupReasons] };
});

export const getChangeGroupReasons = (group: ONCE.ModerationProfileGroup) => {
  if (group === ONCE.ModerationProfileGroup.Common) {
    return {
      reasonsList: decreaseGroupReasonsList,
    };
  }

  return {
    reasonsList: increaseGroupReasonsList,
  };
};

export const getReasonFromSelected = (selectedReasons: Record<string, boolean>, customReason: string) => {
  const reasons = Object.keys(selectedReasons).reduce<string[]>((acc, curr) => {
    if (increaseGroupReasons[curr as keyof typeof increaseGroupReasons]) {
      acc.push(increaseGroupReasons[curr as keyof typeof increaseGroupReasons]);
    }

    if (decreaseGroupReasons[curr as keyof typeof decreaseGroupReasons]) {
      acc.push(decreaseGroupReasons[curr as keyof typeof decreaseGroupReasons]);
    }
    return acc;
  }, []);

  return reasons.join(', ') + (customReason ? `, ${customReason}` : '');
};
