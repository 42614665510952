import React, { useMemo, useState } from 'react';

import Typography from '@material-ui/core/Typography';

import { ONCE } from 'src/types/once';
import {
  onceModerationPhotoSize,
  PhotoTransformation,
  useGetMediaSourceAuthed,
} from 'src/components/Moderation/utils/image-source';
import { mapCategoryLevels } from 'src/components/Moderation/screens/UserModeration/once/utils';

import { ModerationContentDialog } from '../../moderation-content-dialog';

import { useStyles } from './styles';

import { ModerationContentImageWithPlaceholder } from './image-with-placeholder';

type Props = {
  decision: ONCE.ContentDecision;
  isAdministrator: boolean;
  userId: string;
};

export const OnceModerationContentCardPhoto = React.memo((props: Props) => {
  const { isAdministrator, userId, decision } = props;

  const { initiatedBy, content: basename, contentType, categoryLevels = [] } = decision;
  const classes = useStyles();

  const getMediaSource = useGetMediaSourceAuthed();

  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleCardClick = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const mediaSource = useMemo(
    () =>
      getMediaSource(userId, basename, 'image', false, onceModerationPhotoSize, PhotoTransformation.KeepAspectRatio),
    [getMediaSource, basename, userId],
  );

  return (
    <>
      <button type="button" className={classes.container} onClick={handleCardClick}>
        <ModerationContentImageWithPlaceholder source={mediaSource} className={classes.photo} />

        {isAdministrator && initiatedBy && (
          <div className={classes.authorContainer}>
            <Typography className={classes.authorText}>{initiatedBy}</Typography>
          </div>
        )}
      </button>

      <ModerationContentDialog
        contentType={contentType}
        isOpen={isDialogOpen}
        handleClose={handleClose}
        content={mediaSource}
        categoryLevels={mapCategoryLevels(categoryLevels)}
      />
    </>
  );
});
