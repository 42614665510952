import React, { useMemo, useState } from 'react';

import { ONCE } from 'src/types/once';
import {
  onceModerationPhotoSize,
  PhotoTransformation,
  useGetMediaSourceAuthed,
} from 'src/components/Moderation/utils/image-source';
import { ImageWithLogger } from 'src/components/Moderation/views/image-with-logger';
import { ModerationContent, ModerationContentItem } from 'src/components/Moderation/screens/UserModeration/once/utils';
import { ModerationContentDialog } from 'src/components/Moderation/screens/UserModeration/once/components/moderation-content-dialog';

import { ModeratedTag } from '../../moderated-tag';
import { ModerationReadOnlyLabel } from '../../readonly-label';

import { useStyles } from './styles';

type Props = {
  isAdministrator: boolean;
  moderationContent: ModerationContentItem;
  setModerationContent: (
    field: keyof ModerationContent,
    category: string,
    level: ONCE.ContentDecisionCategoryLevelType,
    mediaContent?: string,
  ) => void;
  setProfileThumbnail: (basename: string) => void;
  userId: string;
  profileThumbnail?: string;
};

export const ModerationProfileMediaCard = React.memo((props: Props) => {
  const {
    isAdministrator,
    moderationContent,
    setModerationContent,
    setProfileThumbnail,
    userId,
    profileThumbnail,
  } = props;

  const getMediaSource = useGetMediaSourceAuthed();

  const { categoryLevels, content, initiatedBy, isModerated, isReadonly, isToModerate } = moderationContent;

  const basename = content;
  const isThumbnail = profileThumbnail ? basename.startsWith(profileThumbnail) : false;

  const classes = useStyles({ isThumbnail, isToModerate });

  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleCardClick = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const setCategoryLevel = (category: string, level: ONCE.ContentDecisionCategoryLevelType) => {
    setModerationContent('newMedia', category, level, basename);
  };

  const mediaSource = useMemo(
    () =>
      getMediaSource(userId, basename, 'image', false, onceModerationPhotoSize, PhotoTransformation.KeepAspectRatio),
    [getMediaSource, basename, userId],
  );

  return (
    <>
      <div className={classes.cardContainer}>
        <button type="button" className={classes.imageButton} onClick={handleCardClick}>
          <ImageWithLogger
            imageContainerStyle={classes.imageContainer}
            imageStyle={classes.image}
            src={mediaSource}
            basename={basename}
            alt="profileMedia"
            detectCelebrity={false}
          />

          <div className={classes.moderationBorder} />
          {isReadonly && <ModerationReadOnlyLabel />}
          {isModerated && <ModeratedTag />}

          {!isReadonly && isAdministrator && initiatedBy && (
            <div className={classes.authorContainer}>
              <span className={classes.authorText}>{initiatedBy}</span>
            </div>
          )}
        </button>

        <button type="button" className={classes.mainButtonContainer} onClick={() => setProfileThumbnail(basename)}>
          <span className={classes.mainButtonText}>Main</span>
        </button>
      </div>

      <ModerationContentDialog
        categoryLevels={categoryLevels}
        content={mediaSource}
        contentType={ONCE.ContentDecisionConentType.Photo}
        handleClose={handleClose}
        isOpen={isDialogOpen}
        isReadonly={isReadonly}
        setCategoryLevel={setCategoryLevel}
      />
    </>
  );
});
