import { ONCE } from 'src/types/once';

import { ModerationContentItem } from './types';
import { mapCategoryLevels } from './map-category-levels';

export const getModerationContentItem = (
  item: ONCE.ModeratingContent | ONCE.CurrentContents,
): ModerationContentItem => {
  if ('categoryLevels' in item) {
    // Handle ONCE.ModeratingContent
    return {
      categoryLevels: mapCategoryLevels(item.categoryLevels),
      content: item.content,
      contentId: item.contentId,
      contentSource: item.contentSource,
      contentType: item.contentType,
      initiatedBy: item.initiatedBy,
      isModerated: false,
      isToModerate: true,
      isReadonly: false,
    };
  }

  // Handle ONCE.CurrentContents
  return {
    categoryLevels: {},
    content: item.content,
    contentId: item.contentId || 0,
    contentSource: item.contentSource,
    contentType: item.contentType,
    initiatedBy: item.initiatedBy,
    isModerated: false,
    isToModerate: false,
    isReadonly: !item.isProcessed,
  };
};
