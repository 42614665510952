import React, { memo, useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { Media, MediaTag, MediaType } from 'src/types/user';
import { FullSizeMedia } from 'src/components/Moderation/views/full-size-media';
import { useAIDecisionsForPhotos } from 'src/services/flure-ai-decision/hooks';

import { MediaItem, UserInfo, VerificationMediaItem } from './components';
import { FullSizeMediaType } from './types';
import { usePrepareMedia } from './hooks';
import { useStyles } from './styles';

type Props = {
  userId: string;
  userMedia: Media[];
  newMediaBaseName?: string;
  isPhotoRejected?: boolean;
  userInfoString: string;
  showHiddenMedia?: boolean;
  declinedCount?: number;
  mediaTags: Record<string, MediaTag[]>;
  setMediaTags: React.Dispatch<React.SetStateAction<Record<string, MediaTag[]>>>;
};

const emptyFullSizeMedia = {} as FullSizeMediaType;

const MediaContentView = (props: Props) => {
  const {
    userId,
    userMedia,
    newMediaBaseName,
    isPhotoRejected,
    userInfoString,
    showHiddenMedia = false,
    declinedCount,
    mediaTags,
    setMediaTags,
  } = props;
  const { sortedUserMedia, downloadUserMedia } = usePrepareMedia({
    userId,
    userMedia,
    newMediaBaseName,
    showHiddenMedia,
  });
  const { aiDecisionsForPhotos, fetchAIDecisionsForPhotos } = useAIDecisionsForPhotos();

  const [isFullSizeOpen, setIsFullSizeOpen] = useState<boolean>(false);
  const [fullSizeMedia, setFullSizeMedia] = useState<FullSizeMediaType>(emptyFullSizeMedia);
  const [showNoThumbnailLabel, setShowNoThumbnailLabel] = useState(false);

  const classes = useStyles();

  const openFullSizeMedia = useCallback((media: FullSizeMediaType) => {
    setFullSizeMedia(media);
    setIsFullSizeOpen(true);
  }, []);

  useEffect(() => {
    const mediaWithTags = userMedia.reduce<Record<string, MediaTag[]>>((acc, media) => {
      acc[media.baseName] = media.tags;

      return acc;
    }, {});

    const hasThumbnailMedia = userMedia.some((media) => media.tags.includes(MediaTag.Thumbnail));

    setMediaTags!(mediaWithTags);
    setShowNoThumbnailLabel(!hasThumbnailMedia);
  }, [setMediaTags, userMedia]);

  useEffect(() => {
    const hasThumbnailMedia = Object.values<MediaTag[]>(mediaTags).some((tags) => tags.includes(MediaTag.Thumbnail));
    setShowNoThumbnailLabel(!hasThumbnailMedia);
  }, [mediaTags]);

  useEffect(() => {
    fetchAIDecisionsForPhotos(userId);
  }, [fetchAIDecisionsForPhotos, userId]);

  return (
    <Box className={classes.mediaWithInfoContainer}>
      {isFullSizeOpen && (
        <FullSizeMedia
          isOpen={isFullSizeOpen}
          setIsOpen={setIsFullSizeOpen}
          source={fullSizeMedia.source}
          mediaType={MediaType.Photo}
          basename={fullSizeMedia.baseName}
        />
      )}
      <span className={clsx(classes.warningLabel, showNoThumbnailLabel && classes.warningLabelOpacity)}>
        No thumbnail selected!
      </span>
      <Box className={classes.mediaBoard}>
        <div className={classes.mediaListContainer}>
          {sortedUserMedia.map((media) =>
            media ? (
              <MediaItem
                key={media.baseName}
                media={media}
                userId={userId}
                newMediaBaseName={newMediaBaseName}
                isPhotoRejected={isPhotoRejected}
                openFullSizeMedia={openFullSizeMedia}
                mediaTags={mediaTags}
                setMediaTags={setMediaTags}
                aiDecision={aiDecisionsForPhotos.value?.moderationPhotos?.[media.baseName]}
                hasSelectedThumbnail={!showNoThumbnailLabel}
              />
            ) : (
              <VerificationMediaItem
                key="verification"
                userId={userId}
                aiDecision={aiDecisionsForPhotos.value?.verificationPhoto}
              />
            ),
          )}
        </div>
      </Box>
      <UserInfo
        userId={userId}
        userInfoString={userInfoString}
        declinedCount={declinedCount}
        downloadUserMedia={downloadUserMedia}
      />
    </Box>
  );
};

export const MediaContent = memo(MediaContentView);
