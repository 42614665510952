import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'src/utils/snackbar';

import { ONCE } from 'src/types/once';
import { onceModerationRequest } from 'src/network/moderation/once';
import { useModerationProfilesCount, useModerationQueueCount } from 'src/components/Moderation/hooks/once';

import { mapQueueToModerationData, ModerationContent, ModerationProfileData } from '../utils';

const queueCountRequestDelay = 10 * 1000; // 10 seconds

export const useProfileToModerate = (isModerationByUserId: boolean) => {
  const queueCountTimeRef = useRef<number | undefined>();
  const versionRef = useRef<number | null>(null);
  const lockSecondsRef = useRef<number | null>(null);

  const [processing, setProcessing] = useState(false);
  const [isQueueLoading, setQueueLoading] = useState(false);
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [profileData, setProfileData] = useState<ModerationProfileData | null>(null);
  const [moderationContent, setModerationContent] = useState<ModerationContent | null>(null);

  const history = useHistory();
  const { showErrorMessage, showSuccessMessage } = useSnackbar();
  const { queueCount, fetchModerationQueueCount } = useModerationQueueCount();
  const { fetchModerationProfilesCount, updateModeratedProfilesCount, profilesCount } = useModerationProfilesCount();

  const loadNextProfile = useCallback(
    async (nextUserId?: string) => {
      if (!queueCountTimeRef.current || Date.now() - queueCountTimeRef.current >= queueCountRequestDelay) {
        fetchModerationQueueCount().then(() => {
          queueCountTimeRef.current = Date.now();
        });
      }

      setQueueLoading(true);

      try {
        const moderationQueue = nextUserId
          ? await onceModerationRequest.getModerationQueueByUserId(nextUserId)
          : await onceModerationRequest.getModerationQueueNext();

        if (!moderationQueue) {
          setProfileData(null);
          setModerationContent(null);
          setQueueLoading(false);
          return;
        }

        const { lockSeconds, version = null } = moderationQueue;

        versionRef.current = version;
        lockSecondsRef.current = lockSeconds;

        const moderationData = mapQueueToModerationData(moderationQueue);
        setModerationContent(moderationData.moderationContent);
        setProfileData(moderationData.profileData);
        setQueueLoading(false);
      } catch (err) {
        showErrorMessage(`Failed to load new Profile ` + (err.message || 'Bad request'));
        setErrorCode(err?.response?.status);
        setQueueLoading(false);
      }
    },
    [fetchModerationQueueCount, showErrorMessage],
  );

  const onModerationSubmit = useCallback(
    async (payload: Pick<ONCE.ModerationResult, 'userContents' | 'actions'>, deleted: boolean) => {
      if (!profileData?.id) {
        return;
      }

      try {
        setProcessing(true);

        await onceModerationRequest.postModerationResult(profileData.id, {
          version: versionRef.current,
          ...payload,
        });

        if (isModerationByUserId) {
          history.push('/moderation-new');

          const successMsg = deleted ? 'Profile successfully deleted' : 'Profile successfully moderated';
          showSuccessMessage(successMsg, true);

          return;
        }

        await loadNextProfile();
        updateModeratedProfilesCount();
        setProcessing(false);
      } catch (err) {
        setProcessing(false);
        const errMsg = deleted ? 'Failed to delete Profile' : 'Failed to save Profile';
        showErrorMessage(`${errMsg} ` + (err.message || 'Bad request'));
      }
    },
    [
      profileData?.id,
      history,
      updateModeratedProfilesCount,
      isModerationByUserId,
      loadNextProfile,
      showSuccessMessage,
      showErrorMessage,
    ],
  );

  useEffect(() => {
    fetchModerationProfilesCount();
  }, [fetchModerationProfilesCount]);

  return {
    errorCode,
    isQueueLoading,
    loadNextProfile,
    moderationContent,
    onModerationSubmit,
    processing,
    profileData,
    profilesCount,
    queueCount,
  };
};
