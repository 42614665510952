import { useCallback } from 'react';

import { Key, KeyTitlesMapper, useKeyboardEventListener } from 'src/components/Moderation/utils/keyboard';
import { ActionsLogger } from 'src/components/Moderation/utils/actions-logger';

type Props = {
  approveAction: () => void;
};

export const useKeyListener = ({ approveAction }: Props) => {
  const keyPressListener = useCallback(
    (event: KeyboardEvent) => {
      if (!event.defaultPrevented) {
        ActionsLogger.addKeyCode(KeyTitlesMapper[event.keyCode]);

        switch (event.keyCode) {
          case Key.Space:
            approveAction();
            break;
          default:
            break;
        }
      }
      return undefined;
    },
    [approveAction],
  );

  useKeyboardEventListener(keyPressListener);
};
