import React from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';

import { ONCE } from 'src/types/once';

import { useStyles } from './styles';

type Props = {
  onGroupPress: (group: ONCE.ModerationProfileGroup) => void;
  onReset: () => void;
  profileGroup: ONCE.ModerationProfileGroup;
  resetDisabled: boolean;
};

export const ChangeGroupFooter = (props: Props) => {
  const { resetDisabled, onReset, onGroupPress, profileGroup } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <Button
          variant="outlined"
          className={clsx(classes.baseButton, classes.resetButton)}
          onClick={onReset}
          disabled={resetDisabled}
        >
          Reset
        </Button>

        <div className={classes.rightContainer}>
          <Button
            variant="outlined"
            className={clsx(classes.baseButton, classes.scamButton)}
            onClick={() => onGroupPress(ONCE.ModerationProfileGroup.Scam)}
            disabled={profileGroup === ONCE.ModerationProfileGroup.Scam}
          >
            Scam
          </Button>
          <Button
            variant="outlined"
            className={clsx(classes.baseButton, classes.animatorsButton)}
            onClick={() => onGroupPress(ONCE.ModerationProfileGroup.Unapproved)}
            disabled={profileGroup === ONCE.ModerationProfileGroup.Unapproved}
          >
            Animators Paradise
          </Button>
          <Button
            variant="outlined"
            className={clsx(classes.baseButton, classes.commonButton)}
            onClick={() => onGroupPress(ONCE.ModerationProfileGroup.Common)}
            disabled={profileGroup === ONCE.ModerationProfileGroup.Common}
          >
            Normal
          </Button>
        </div>
      </div>
    </div>
  );
};
