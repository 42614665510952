import React, { useState } from 'react';
import clsx from 'clsx';
import { Box, Button, IconButton } from '@material-ui/core';
import { CloseIcon } from '@material-ui/data-grid';

import { ModerationDialogModal } from 'src/components/Moderation/screens/UserModeration/once/components/dialog-modal';

import { useStyles as useStylesBase } from '../styles';
import { useStyles } from './styles';

type Props = {
  onDeleteUser: () => void;
};

export const ModerationProfileFooterDelete = (props: Props) => {
  const { onDeleteUser } = props;

  const classesBase = useStylesBase();
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const onConfirm = () => {
    onDeleteUser();
    setIsOpen(false);
  };
  const onReject = () => {
    setIsOpen(false);
  };
  const onDelete = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Button variant="outlined" className={clsx(classesBase.baseButton, classes.deleteButton)} onClick={onDelete}>
        Delete User
      </Button>

      <ModerationDialogModal onClose={onReject} open={isOpen} contentClassName={classes.contentContainer}>
        <span className={classes.modalTitle}>Delete User?</span>
        <span className={classes.modalSubtitle}>The user’s profile will be permanently deleted.</span>

        <Box>
          <Button variant="outlined" className={clsx(classesBase.baseButton, classes.resetButton)} onClick={onReject}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            className={clsx(classesBase.baseButton, classes.modalDeleteButton)}
            onClick={onConfirm}
          >
            Delete User
          </Button>
        </Box>

        <IconButton aria-label="close" onClick={onReject} className={classes.modalIconContainer}>
          <CloseIcon className={classes.modalIcon} />
        </IconButton>
      </ModerationDialogModal>
    </>
  );
};
