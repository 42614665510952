import { ONCE } from 'src/types/once';

export const mapCategoryLevels = (
  categoryLevels: ONCE.ContentDecisionCategoryLevel[],
): Record<string, ONCE.ContentDecisionCategoryLevelType> => {
  return categoryLevels.reduce<Record<string, ONCE.ContentDecisionCategoryLevelType>>((acc, curr) => {
    acc[curr.category] = curr.level;

    return acc;
  }, {});
};
