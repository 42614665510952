import { defaultComponentsOrder, MessageComponents } from '../types';

export const createComponentsArray = (componentsOrder?: MessageComponents[]): MessageComponents[] => {
  const allComponents = Array.from(new Set((componentsOrder ?? []).concat(defaultComponentsOrder)));
  const components: MessageComponents[] = [];
  let photoOrVideoAdded = false;

  allComponents.forEach((component) => {
    if (component === MessageComponents.Photo || component === MessageComponents.Video) {
      if (!photoOrVideoAdded) {
        if (component === MessageComponents.Photo) {
          components.push(MessageComponents.Photo, MessageComponents.Video);
        } else {
          components.push(MessageComponents.Video, MessageComponents.Photo);
        }
        photoOrVideoAdded = true;
      }
    } else {
      components.push(component);
    }
  });

  return components;
};
