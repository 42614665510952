import { useAsyncFn } from 'react-use';

import { aiDecisionRequest } from 'src/network';

export const usePhotoMediaSources = () => {
  const [mediaSourcesState, fetchMediaSources] = useAsyncFn(
    async () => {
      try {
        const result = await aiDecisionRequest.getPhotoMediaSources();
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return {
    mediaSourcesState,
    fetchMediaSources,
  };
};
