import { useCallback } from 'react';

import { ONCE } from 'src/types/once';
import { useModerationGroupsDecisions } from 'src/components/Moderation/hooks/once';

const fallbackContentDecisions: ONCE.GroupsDecisions[] = [];

export const useProfileGroupsDecisions = (profileId?: string) => {
  const {
    moderationGroupsDecisionsState: groupsDecisionsState,
    fetchModerationGroupsDecisions: fetchGroupsDecisions,
  } = useModerationGroupsDecisions();

  const loadGroupsDecisions = useCallback(() => {
    if (profileId && !groupsDecisionsState?.value?.decisions) {
      fetchGroupsDecisions(profileId);
    }
  }, [profileId, groupsDecisionsState?.value?.decisions, fetchGroupsDecisions]);

  return {
    loadGroupsDecisions,
    groupsDecisions: groupsDecisionsState?.value?.decisions || fallbackContentDecisions,
  };
};
