import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  modalContainer: {
    background: 'rgba(0, 0, 0, 0.10)',
    backdropFilter: 'blur(25px)',
  },

  modalContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },

  modalLeftContainer: {
    display: 'flex',
    width: '480px',
    maxHeight: '70vh',
    marginRight: 32,
    borderRadius: '24px',
    backgroundColor: '#FFFFFF',
    position: 'relative',
    overflow: 'hidden',
  },

  contentPhoto: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },

  contentFallbackPhoto: {
    margin: '220px auto',
  },

  textContainer: {
    border: '1px solid #EDEDED',
    padding: '24px',
    overflowY: 'auto',
  },

  contentText: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#212121',
  },

  modalRightContainer: {
    padding: '24px',
    borderRadius: '24px',
    border: '1px solid #EDEDED',
    backgroundColor: '#FFFFFF',
    width: '570px',
    maxHeight: '70vh',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  modalIconContainer: {
    position: 'absolute',
    right: '24px',
    top: '24px',
    display: 'flex',
    width: '64px',
    height: '64px',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '12px',
    border: '1px solid #EDEDED',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255,255, 0.8)',
    },
  },

  modalIcon: {
    color: 'black',
    width: '24px',
    height: '24px',
  },

  popupContainer: {
    borderRadius: '24px',
  },

  popupContentContainer: {
    position: 'relative',
    padding: '24px',
    paddingTop: '40px',
  },

  popupIconContainer: {
    position: 'absolute',
    top: 4,
    right: 4,
  },

  popupIcon: {
    color: 'black',
  },
});
