import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '8px',
    marginBottom: '32px',
  },

  buttonContainer: {
    display: 'flex',
    borderRadius: '24px',
    border: '1px solid #EDEDED',
    backgroundColor: '#FFFFFF',

    '&:hover': {
      opacity: 0.8,
    },
  },

  itemRedContainer: {
    backgroundColor: '#DA0767',
  },

  itemText: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'start',
    padding: '9px 16px',
  },

  itemRedText: {
    color: '#FFFFFF',
  },
});
