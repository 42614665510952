import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

import { ONCE } from 'src/types/once';
import {
  onceModerationPhotoSize,
  PhotoTransformation,
  useGetMediaSourceAuthed,
} from 'src/components/Moderation/utils/image-source';
import { ModerationContentImageWithPlaceholder } from 'src/components/Moderation/screens/UserModeration/once/components/moderation-content/card-photo/image-with-placeholder';
import { ModerationContentDialog } from 'src/components/Moderation/screens/UserModeration/once/components/moderation-content-dialog';
import { mapCategoryLevels } from 'src/components/Moderation/screens/UserModeration/once/utils';
import { formatDate } from 'src/components/Moderation/screens/UserModeration/once/utils/format-date';

import { useStyles } from './styles';
import { getContentTypeText, mapDecisionToText } from './utils';

type Props = {
  userId: string;
  data: ONCE.ContentDecision;
};

export const ModerationProfileHistoryItem = (props: Props) => {
  const { data, userId } = props;

  const { decision, content, contentType, contentSource, categoryLevels = [], timestamp, initiatedBy } = data;

  const getMediaSource = useGetMediaSourceAuthed();

  const isPhoto = contentType === ONCE.ContentDecisionConentType.Photo;

  const classes = useStyles({ decision });

  const mediaSource = useMemo(
    () =>
      isPhoto
        ? getMediaSource(userId, content, 'image', false, onceModerationPhotoSize, PhotoTransformation.KeepAspectRatio)
        : '',
    [isPhoto, getMediaSource, content, userId],
  );

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [popoverAnchorEl, setAnchorEl] = useState<Element>();

  const handleCardClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isPhoto) {
      setDialogOpen(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    setDialogOpen(false);
  };

  return (
    <>
      <button
        type="button"
        className={classes.container}
        onClick={handleCardClick}
        disabled={decision === ONCE.ContentDecisionType.Approve && !isPhoto}
      >
        <div className={classes.baseText}>{formatDate(timestamp)}</div>
        <div className={clsx(classes.baseText, classes.initiatedByText)}>{initiatedBy}</div>
        <div className={clsx(classes.baseText, classes.contentTypeText)}>
          {getContentTypeText(contentType, contentSource)}
        </div>
        <div className={clsx(classes.baseText, classes.statusText)}>{mapDecisionToText[decision]}</div>
        <div className={classes.contentContainer}>
          {mediaSource ? (
            <ModerationContentImageWithPlaceholder
              source={mediaSource}
              className={classes.image}
              fallbackClasses={classes.image}
            />
          ) : (
            <div className={clsx(classes.baseText, classes.contentText)}>{content}</div>
          )}
        </div>
      </button>

      <ModerationContentDialog
        contentType={contentType}
        content={isPhoto ? mediaSource : content}
        handleClose={handleClose}
        isOpen={isDialogOpen}
        popoverAnchorEl={popoverAnchorEl}
        categoryLevels={mapCategoryLevels(categoryLevels)}
      />
    </>
  );
};
