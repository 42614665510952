import React from 'react';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';

import { useStyles } from './styles';

type Props = {
  label: string;
  tooltip?: string;
  onClick?: () => void;
  containerClassname?: string;
  labelClassname?: string;
};

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '12px',
    lineHeight: '18px',
    borderRadius: '8px',
  },
}))(Tooltip);

export const ModerationProfileTag = (props: Props) => {
  const { tooltip = '', label, onClick, labelClassname, containerClassname } = props;

  const classes = useStyles();

  return (
    <LightTooltip title={tooltip} disableHoverListener={!tooltip} placement="top">
      <button
        type="button"
        className={clsx(classes.container, onClick && classes.pointer, containerClassname)}
        onClick={onClick}
      >
        <span className={clsx(classes.text, labelClassname)}>{label}</span>
      </button>
    </LightTooltip>
  );
};
