import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  titleText: {
    fontFamily: 'Poppins',
    fontSize: '64px',
    lineHeight: '96px',
    textAlign: 'center',
  },

  subtitleText: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#A8A8A8',
  },
});
