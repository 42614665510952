import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

import { Layout } from 'src/components/App/views/Layout';
import { PhotosList, ScreenHeader } from 'src/components/common/flure/ai-decisions-history';
import { useAIDecisionPhotosHistory } from 'src/services/flure-ai-decision/hooks';
import { AIDecisionPhotoType } from 'src/network/flure-ai-decision/types';

import { useStyles } from './styles';

const defaultSelect = 20;

export const ModerationHistoryFlureScreen = () => {
  const classes = useStyles();
  const [appliedFilters, setAppliedFilters] = useState<string[]>([]);
  const [photoType, setPhotoType] = useState<AIDecisionPhotoType>(AIDecisionPhotoType.Moderation);
  const { loading: isLoading, aiDecisionPhotosHistory, fetchAIDecisionPhotosHistory } = useAIDecisionPhotosHistory();

  const renderLoader = useCallback(() => {
    return (
      <div className={classes.loader}>
        <CircularProgress color="secondary" />
      </div>
    );
  }, [classes.loader]);

  const loadMore = useCallback(() => {
    fetchAIDecisionPhotosHistory({
      omit: aiDecisionPhotosHistory?.length,
      select: defaultSelect,
      mediaSources: appliedFilters,
      reload: false,
      type: photoType,
    });
  }, [fetchAIDecisionPhotosHistory, aiDecisionPhotosHistory?.length, appliedFilters, photoType]);

  useEffect(() => {
    fetchAIDecisionPhotosHistory({
      omit: 0,
      select: defaultSelect,
      mediaSources: appliedFilters,
      reload: true,
      type: photoType,
    });
  }, [appliedFilters, fetchAIDecisionPhotosHistory, photoType]);

  if (!aiDecisionPhotosHistory?.length) {
    return (
      <Layout containerSize="xl">
        <ScreenHeader
          title="Moderation Photos"
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          photoType={photoType}
          setPhotoType={setPhotoType}
        />
        {isLoading ? (
          renderLoader()
        ) : (
          <Typography className={classes.placeholderText} variant="h4">
            No data available
          </Typography>
        )}
      </Layout>
    );
  }

  return (
    <Layout containerSize="xl">
      <ScreenHeader
        title="Moderation Photos"
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        photoType={photoType}
        setPhotoType={setPhotoType}
      />
      <PhotosList data={aiDecisionPhotosHistory} photoType={photoType} loadMore={loadMore} />
    </Layout>
  );
};
