import React from 'react';

import { Layout } from 'src/components/App/views/Layout';
import { VerificationWidgetFlure } from 'src/components/Verification/views/VerificationWidgetFlure';

export const VerificationScreen = () => {
  return (
    <Layout containerSize="xl">
      <VerificationWidgetFlure />
    </Layout>
  );
};
