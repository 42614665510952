import moment from 'moment';

import { Product } from 'src/types/product';
import { DefaultReportSearchMode, Reports } from 'src/types/report/common';
import { MessagesReportDataItem, MessagesReportFormFields, ReportMessageCopilotUsage } from 'src/types/report/messages';
import { fillExcelDocument } from 'src/utils/excel';
import { downloadFile } from 'src/utils/files';

const columnNames = [
  'Operator Id',
  'Animator Id',
  'Client Id',
  'Agency Name',
  'Operator Name',
  'Date',
  'Time',
  'Text',
  'Status',
  'Manual or Co-Pilot',
];

const getCopilotUsageFormatted = (copilotUsage: MessagesReportDataItem['copilotUsage']) => {
  switch (copilotUsage) {
    case ReportMessageCopilotUsage.Edited:
      return 'Co-Pilot Edited';
    case ReportMessageCopilotUsage.Used:
      return 'Co-Pilot As-Is';
    default:
      return 'Manual';
  }
};

const convertMessagesReport = (json: MessagesReportDataItem[]) => {
  return [
    columnNames,
    ...json.map((item) => [
      item.operatorId,
      item.animatorId,
      item.clientId,
      item.agencyName,
      item.operatorName,
      moment(item.timestamp).format('YYYY/MM/DD'),
      moment(item.timestamp).format('HH:mm'),
      item.text,
      item.sent ? 'Delivered' : 'Blocked',
      getCopilotUsageFormatted(item.copilotUsage),
    ]),
  ];
};

export const downloadMessagesReportXlsx = (payload: MessagesReportDataItem[], fileName: string) => {
  const convertedData = convertMessagesReport(payload);
  const document = fillExcelDocument('Report by messages', convertedData);

  downloadFile(document, `${fileName}.xlsx`);
};

export const getFileName = (realm: Product, filter?: MessagesReportFormFields): string => {
  if (!filter) {
    return '';
  }

  const reportName = Reports.messages;
  const formattedDateRange = filter.rangeDate.replace(':', '__');

  const reportMapping: Record<DefaultReportSearchMode, string> = {
    agency: 'agency',
    all: 'all',
    'all-without-agency': 'without-agency',
    operator: 'operator',
  };
  const operator = filter.operatorId ? `-${filter.operatorId}` : '';
  const agency = filter.agencyTechName ? `-${filter.agencyTechName}` : '';

  return `${realm}-${reportName}-${reportMapping[filter.mode]}${agency}${operator}-${formattedDateRange}`;
};
