import { useAsyncFn } from 'react-use';

import { aiDecisionRequest } from 'src/network';
import {
  AIDecisionForPhoto,
  AIDecisionForVerificationPhoto,
  AIDecisionPhotoType,
} from 'src/network/flure-ai-decision/types';

export const useAIDecisionsForPhotos = () => {
  const [aiDecisionsForPhotos, fetchAIDecisionsForPhotos] = useAsyncFn(
    async (userId: string) => {
        const moderationPhotos = await aiDecisionRequest.getAIDecisionsForPhotos({
          userId,
          select: 100,
          type: AIDecisionPhotoType.Moderation,
        });
        const verificationPhotos = await aiDecisionRequest.getAIDecisionsForPhotos({
          userId,
          select: 1,
          type: AIDecisionPhotoType.Verification,
        });

        return {
          moderationPhotos,
          verificationPhoto: verificationPhotos?.[Object.keys(verificationPhotos)[0]],
        } as {
          moderationPhotos: Record<string, AIDecisionForPhoto>;
          verificationPhoto?: AIDecisionForVerificationPhoto;
        };
    },
    [],
    { loading: true },
  );

  return { aiDecisionsForPhotos, fetchAIDecisionsForPhotos };
};
