import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  container: {
    backgroundColor: '#F2F2F2',
    borderRadius: '8px',
    padding: '4px 12px',
    border: 'none',
  },

  pointer: {
    cursor: 'pointer',
  },

  text: {
    fontFamily: 'Poppins',
    fontSize: '10px',
    lineHeight: '15px',
    fontWeight: 600,
    color: '#6B6B6B',
    textTransform: 'uppercase',
  },
});
