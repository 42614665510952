import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() => {
  return {
    modalContainer: {
      background: 'rgba(0, 0, 0, 0.10)',
      backdropFilter: 'blur(25px)',
    },

    modalContentContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },

    contentContainer: {
      maxWidth: '480px',
      padding: '40px 56px',
      borderRadius: '24px',
      border: '1px solid #EDEDED',
      backgroundColor: '#FFFFFF',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  };
});
