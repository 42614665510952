export type AIDecisionForTextResponse = {
  flagged: boolean;
  success: boolean;
  categories: string;
};

export type AIDecisionForVerificationPhoto = {
  isRecognized?: boolean | null;
};

export type AIDecisionForPhoto = {
  approved: boolean;
  spicy: boolean;
  suitableForProfilePic: boolean;
  reason: string;
};

export type PhotoHistoryItem = {
  basePath: string;
  baseName: string;
  aiDecision: AIDecisionForVerificationPhoto | AIDecisionForPhoto;
  mediaSource?: string;
  created: string;
};

export interface AIDecisionPhotosHistoryResponse {
  models: PhotoHistoryItem[];
}

export type PhotoMediaSourcesResponse = {
  mediaSources: string[];
};

export type AIDecisionsForPhotosResponse = {
  photos: Record<string, AIDecisionForPhoto | AIDecisionForVerificationPhoto>;
};

export enum AIDecisionPhotoType {
  Moderation = 'moderation',
  ModerationExperiment = 'moderation.exp',
  Verification = 'verification',
}
