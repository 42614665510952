import { makeStyles, Theme } from '@material-ui/core';

import { ONCE } from 'src/types/once';

const mapDecisionToColor = {
  [ONCE.ContentDecisionType.Approve]: '#00C376',
  [ONCE.ContentDecisionType.ToModeration]: '#A8A8A8',
  [ONCE.ContentDecisionType.Reject]: '#F31D1D',
  [ONCE.ContentDecisionType.Change]: '#A8A8A8',
};

export const useStyles = makeStyles<Theme, { decision: ONCE.ContentDecisionType }>(() => {
  return {
    container: {
      minHeight: '88px',
      padding: '16px 40px',
      borderRadius: '12px',
      border: '1px solid #EDEDED',
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
      marginBottom: '8px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      cursor: 'pointer',

      '&:hover': {
        opacity: 0.8,
      },

      '&:disabled': {
        cursor: 'default',
      },
    },

    baseText: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      lineHeight: '21px',
      color: '#212121',
      flex: 1,
      marginRight: '24px',
      textAlign: 'start',
    },

    initiatedByText: {
      flex: 0.7,
    },

    contentTypeText: {
      flex: 0.7,
    },

    statusText: {
      color: ({ decision }) => mapDecisionToColor[decision],
    },

    contentContainer: {
      flex: 1.7,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },

    contentText: {
      marginRight: '0px',
      wordBreak: 'break-word',
    },

    image: {
      width: 'auto',
      height: '54px',
      borderRadius: '4px',
      objectFit: 'contain',
    },
  };
});
