import { useState } from 'react';
import { useAsyncFn } from 'react-use';
import { aiDecisionRequest } from 'src/network';
import { PhotoHistoryItem } from 'src/network/flure-ai-decision/types';

export const useAIDecisionPhotosHistory = () => {
  const [aiDecisionPhotosHistory, setAIDecisionPhotosHistory] = useState<PhotoHistoryItem[]>([]);

  const [aiDecisionPhotosHistoryState, fetchAIDecisionPhotosHistory] = useAsyncFn(
    async ({ omit, select, mediaSources, reload, type }) => {
      if (reload) {
        setAIDecisionPhotosHistory([]);
      }

      const result = await aiDecisionRequest.getAIDecisionPhotosHistory({ omit, select, type, mediaSources });

      if (reload) {
        setAIDecisionPhotosHistory(result);
        return result;
      }

      setAIDecisionPhotosHistory((state) => [...state, ...result]);
      return [...aiDecisionPhotosHistory, ...result];
    },
    [],
    { loading: true },
  );

  return {
    loading: aiDecisionPhotosHistoryState.loading,
    aiDecisionPhotosHistory,
    fetchAIDecisionPhotosHistory,
  };
};
