import { useCallback, useEffect } from 'react';

import { ONCE } from 'src/types/once';
import { useModerationContentDecisions } from 'src/components/Moderation/hooks/once';

const fallbackContentDecisions: ONCE.ContentDecision[] = [];

export const useProfileContentDecisions = (profileId?: string) => {
  const {
    moderationContentDecisionsState: rejectedDecisionsState,
    fetchModerationContentDecisions: fetchRejectedDecisions,
  } = useModerationContentDecisions();
  const {
    moderationContentDecisionsState: decisionsHistoryState,
    fetchModerationContentDecisions: fetchDecisionsHistory,
  } = useModerationContentDecisions();

  const loadDecisionsHistory = useCallback(() => {
    if (profileId && !decisionsHistoryState?.value?.decisions) {
      fetchDecisionsHistory(profileId);
    }
  }, [profileId, decisionsHistoryState?.value?.decisions, fetchDecisionsHistory]);

  useEffect(() => {
    if (profileId) {
      fetchRejectedDecisions(profileId, 'reject');
    }
  }, [profileId, fetchRejectedDecisions]);

  return {
    loadDecisionsHistory,
    rejectedDecisions: rejectedDecisionsState.value?.decisions || fallbackContentDecisions,
    decisionsHistory: decisionsHistoryState.value?.decisions || fallbackContentDecisions,
  };
};
