import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() => {
  return {
    button: {
      marginTop: '24px',
      backgroundColor: '#FFFFFF',
      height: '48px',
      width: '100px',
      borderRadius: '12px',
      border: '1px solid #EDEDED',
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',

      '& .MuiButton-label': {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '21px',
        color: '#000000',
        textTransform: 'none',
      },
    },
  };
});
