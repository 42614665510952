import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const maxOnScreenPresenceTime = 10 * 60 * 1000; // 10 minutes

export const useModerationTimeout = (enabled: boolean, userId?: string) => {
  const timerRef = useRef<number>();

  const history = useHistory();

  const [timeoutModalVisible, setTimeoutModal] = useState(false);

  const onTimeoutModalClose = useCallback(() => {
    history.push('/moderation-new');
  }, [history]);

  useEffect(() => {
    if (enabled && userId) {
      timerRef.current = setTimeout(() => {
        setTimeoutModal(true);
      }, maxOnScreenPresenceTime);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [enabled, userId]);

  return {
    onTimeoutModalClose,
    timeoutModalVisible,
  };
};
