import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() => {
  return {
    contentContainer: {
      width: '100%',
      display: 'flex',
      maxWidth: 1072,
      marginTop: 15,
      flexDirection: 'column',
      alignSelf: 'center',
    },
  };
});
