import { makeStyles, Theme } from '@material-ui/core';

export const buttonBgHelper = (color: string, backgroundColor: string) => ({
  color,
  backgroundColor,
  '&:hover': {
    color,
    backgroundColor,
    opacity: 0.8,
  },
  '&.Mui-disabled': {
    color,
    backgroundColor,
    opacity: 0.4,
  },
});

export const useStyles = makeStyles<Theme>(() => {
  return {
    container: {
      backgroundColor: '#FFFFFF',
      position: 'sticky',
      height: '112px',
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      padding: '24px 96px',
      border: '1px solid #EDEDED',
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
    },

    contentContainer: {
      flexGrow: 1,
      maxWidth: '1440px',
      backgroundColor: '#FFFFFF',
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },

    leftContainer: {
      display: 'flex',
    },

    baseButton: {
      backgroundColor: '#FFFFFF',
      color: '#000000',
      padding: '14px 48px',
      height: '64px',
      borderRadius: '12px',
      border: '1px solid #EDEDED',
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',

      '& .MuiButton-label': {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        textTransform: 'none',
      },
    },

    resetButton: {
      ...buttonBgHelper('#121212', '#FFFFFF'),

      '&.Mui-disabled': {
        color: '#EDEDED',
        backgroundColor: '#FFFFFF',
      },
    },

    saveButton: {
      ...buttonBgHelper('#FFFFFF', '#000000'),
    },

    saveButtonTextContainer: {
      display: 'flex',
      flexDirection: 'column',
    },

    saveButtonSecondaryText: {
      fontFamily: 'Poppins',
      fontSize: '8px',
      lineHeight: '12px',
      color: '#FFFFFF',
      opacity: 0.5,
    },
  };
});
