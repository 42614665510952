import { useCallback, useEffect, useState } from 'react';

import { ONCE } from 'src/types/once';

import { ModerationContent, getUpdatedModerationContentItem } from '../utils';

export const useContentToModerate = (userContent: ModerationContent | null) => {
  const [contentToModerate, setContentToModerate] = useState<ModerationContent>({});

  const handleSetModerationContent = useCallback(
    (
      field: keyof ModerationContent,
      category: string,
      level: ONCE.ContentDecisionCategoryLevelType,
      mediaContent?: string,
    ) => {
      setContentToModerate((prevModerationContent) => {
        if (field === 'newMedia') {
          if (!mediaContent) return prevModerationContent;

          const initialModerationContentItem = userContent?.[field]?.[mediaContent];
          const stateModerationContentItem = prevModerationContent[field]?.[mediaContent];

          if (!initialModerationContentItem) return prevModerationContent;

          const updatedItem = getUpdatedModerationContentItem(
            category,
            level,
            initialModerationContentItem,
            stateModerationContentItem,
          );

          return {
            ...prevModerationContent,
            [field]: {
              ...prevModerationContent[field],
              [mediaContent]: updatedItem,
            },
          };
        }

        const initialModerationContentItem = userContent?.[field];
        const stateModerationContentItem = prevModerationContent[field];

        if (!initialModerationContentItem) return prevModerationContent;

        const updatedItem = getUpdatedModerationContentItem(
          category,
          level,
          initialModerationContentItem,
          stateModerationContentItem,
        );

        return {
          ...prevModerationContent,
          [field]: updatedItem,
        };
      });
    },
    [userContent],
  );

  const resetContent = useCallback(() => {
    setContentToModerate(userContent || {});
  }, [userContent]);

  useEffect(() => {
    resetContent();
  }, [resetContent]);

  return {
    contentToModerate,
    resetContent,
    setModerationContent: handleSetModerationContent,
  };
};
