import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 10,
    paddingBottom: '21px',
  },

  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '20px',
    alignItems: 'center',
  },

  button: {
    padding: '10px 16px',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid transparent',
    borderRadius: '35px',
    textTransform: 'none',
  },

  buttonActive: {
    borderColor: 'black'
  },

  bubble: {
    display: 'flex',
    padding: '0px 5px',
    borderRadius: '100px',
    backgroundColor: '#B2F68887',
    fontFamily: 'Poppins',
    fontSize: '14px',
    marginLeft: '10px'
  },
});
