import React from 'react';

import { useStyles } from './styles';

export const ModerationContentDialogReadonlyPlaceholder = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.titleText}>😓</div>
      <div className={classes.subtitleText}>You cannot edit this photo</div>
    </>
  );
};
