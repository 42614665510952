import { ONCE } from 'src/types/once';
import { Gender } from 'src/types/user';

import { ModerationContent, ModerationContentItem, ModerationProfileData } from '../utils';

export type DataContextType = {
  contentToModerate: ModerationContent;
  decisionsHistory: ONCE.ContentDecision[];
  errorCode: number | null;
  groupsDecisions: ONCE.GroupsDecisions[];
  initialProfileGroup: ONCE.ModerationProfileGroup;
  isAdministrator: boolean;
  isModerationByUserId: boolean;
  isQueueLoading: boolean;
  moderationMode: ModerationMode | null;
  processing: boolean;
  profileData: ModerationProfileData | null;
  profileGender?: Gender;
  profileGroup: ONCE.ModerationProfileGroup;
  profileGroupReason: string;
  profileMedia: ModerationContentItem[];
  profileThumbnail?: string;
  profilesCount: number;
  queueCount: number;
  rejectedDecisions: ONCE.ContentDecision[];
  resetDisabled: boolean;
  userIdParams?: string;
};

export enum ModerationMode {
  History = 'history',
  ChangeGroup = 'change-group',
}

export type ActionsContextType = {
  setModerationMode: (mode: ModerationMode | null) => void;
  setProfileThumbnail: (basename: string) => void;
  setProfileGroup: (group: ONCE.ModerationProfileGroup, reason: string) => void;
  setModerationContent: (
    field: keyof ModerationContent,
    category: string,
    level: ONCE.ContentDecisionCategoryLevelType,
  ) => void;
  toggleUserGender: () => void;
  onDelete: () => void;
  onReset: () => void;
  resetProfileGroup: () => void;
  onSave: () => void;
  loadDecisionsHistory: () => void;
  loadGroupsDecisions: () => void;
  loadNextProfile: () => void;
};
