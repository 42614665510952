import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  photo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  fallbackPhoto: {
    width: '120px',
    height: '120px',
    objectFit: 'contain',
  },

  fallbackText: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#A8A8A8',
  },
});
