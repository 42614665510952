import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  container: {
    width: '100%',
    display: 'flex',
    marginBottom: '24px',
    justifyContent: 'flex-start',
    gap: 8,
    flexWrap: 'wrap',
  },

  unapprovedContainer: {
    backgroundColor: '#FF7A2F',
  },

  unapprovedText: {
    color: '#FFFFFF',
  },

  scamContainer: {
    backgroundColor: '#F31D1D',
  },

  scamText: {
    color: '#FFFFFF',
  },
});
