import { CommonReportRequestParams } from '../common/types';

export type GetReportByMessagesRequestParams = CommonReportRequestParams & {
  operator: string | null;
  agencyTechName: string | null;
  sent: boolean | null;
};

export enum ReportMessageCopilotUsage {
  Used = 'used',
  Edited = 'edited',
  NotUsed = 'not-used',
}

export type ReportMessageDto = {
  operatorId: number;
  operatorName: string;
  agencyName: string;
  clientId: number;
  animatorId: number;
  timestamp: string;
  text: string;
  sent: boolean;
  copilotUsage: ReportMessageCopilotUsage | null;
};

export type MessagesReportDto = {
  messages: ReportMessageDto[];
};
