import { makeStyles } from '@material-ui/core';

const smallImageHeight = '210px';
const smallImageWidth = '122px';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
  },

  verificationPhotoContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: smallImageHeight,
    width: smallImageWidth,
  },

  mediaName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '22px',
    borderRadius: '15px',
    backgroundColor: 'rgba(247, 247, 247, 1)',

    color: 'black',
    fontFamily: 'Poppins',
    fontSize: '9px',
    lineHeight: '14px',
  },

  verificationImageContainer: {
    height: smallImageHeight,
    width: smallImageWidth,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginRight: '0px',
  },

  verificationImage: {
    height: smallImageHeight,
    maxHeight: smallImageHeight,
    width: smallImageWidth,
    objectFit: 'contain',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },

  hidden: {
    visibility: 'hidden',
  },

  loaderContainer: {
    position: 'absolute',
    marginTop: '-5px',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  aiBubble: {
    position: 'absolute',
    top: '11px',
    right: '11px',
    cursor: 'pointer'
  },
});
