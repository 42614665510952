import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { OutlinedInput } from '@material-ui/core';

import { useKeyboardEventListener } from 'src/components/Moderation/utils/keyboard';

import { ModerationProfileTag } from '../content/profile-tag';

import { useStyles } from './styles';

type Props = {
  searchFieldEnabled: boolean;
  queueCount: number;
  profilesCount: number;
};

export const ModerationProfileHeader = (props: Props) => {
  const { searchFieldEnabled, profilesCount, queueCount } = props;

  const classes = useStyles();

  const history = useHistory();

  const [searchText, setSearchText] = useState('');

  const keyPressListener = useCallback(
    (event: KeyboardEvent) => {
      if (searchFieldEnabled && searchText && !event.defaultPrevented && event.key === 'Enter') {
        history.push(`/moderation-new/user/${searchText}`);
      }
    },
    [history, searchFieldEnabled, searchText],
  );

  useKeyboardEventListener(keyPressListener);

  return (
    <div className={classes.container}>
      {searchFieldEnabled && (
        <OutlinedInput
          placeholder="Search by ID"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          className={classes.textInput}
          labelWidth={0}
          type="number"
        />
      )}

      <div className={classes.indicatorsContainer}>
        <ModerationProfileTag label={`🙂 ${queueCount}`} tooltip="Users in the queue" />
        <ModerationProfileTag label={`✅ ${profilesCount}`} tooltip="You've done" />
      </div>
    </div>
  );
};
