import React from 'react';

import { useStyles } from './styles';

export const ModerationReadOnlyLabel = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span className={classes.title}>Read Only</span>
    </div>
  );
};
