import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme, { wrapItems?: boolean }>({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: ({ wrapItems }) => (wrapItems ? 'row' : 'column'),
    alignItems: ({ wrapItems }) => (wrapItems ? 'normal' : 'flex-start'),
    flexWrap: ({ wrapItems }) => (wrapItems ? 'wrap' : 'nowrap'),
    gap: ({ wrapItems }) => (wrapItems ? '8px' : '0px'),
  },

  buttonContainer: {
    display: 'flex',
    borderRadius: '24px',
    border: '1px solid #EDEDED',
    backgroundColor: '#FFFFFF',
    marginBottom: ({ wrapItems }) => (wrapItems ? '0px' : '8px'),

    '&:hover': {
      opacity: 0.8,
    },

    '&:disabled': {
      color: '#000000',
      backgroundColor: '#FFFFFF',
    },
  },

  itemYellowContainer: {
    border: '1.5px solid #DA0767',

    '&:disabled': {
      border: '1.5px solid #DA0767',
    },
  },

  itemRedContainer: {
    backgroundColor: '#DA0767',

    '&:disabled': {
      backgroundColor: '#DA0767',
    },
  },

  itemText: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'start',
    padding: '9px 16px',
  },

  itemYellowText: {
    color: '#DA0767',
  },

  itemRedText: {
    color: '#FFFFFF',
  },
});
