import React from 'react';

import { BubbleCheckBox } from './components';
import { useStyles } from './styles';

export type Option = { text: string; value: string };

type Props = {
  options: Option[];
  selected: string[];
  setSelected?: React.Dispatch<React.SetStateAction<string[]>>;
  disabled?: boolean;
  isMultiple?: boolean;
  allowEmpty?: boolean;
};

const BubbleCheckBoxesListView: React.FC<Props> = (props) => {
  const { options, selected, setSelected, disabled, isMultiple = true, allowEmpty = false } = props;
  const classes = useStyles();

  const toggleOption = (value: string) => () => {
    if (setSelected) {
      if (isMultiple) {
        if (selected.includes(value)) {
          if (allowEmpty || selected.length > 1) {
            setSelected(selected.filter((it) => it !== value));
          }
        } else {
          setSelected([...selected, value]);
        }
      } else {
        setSelected([value]);
      }
    }
  };

  return (
    <div className={classes.container}>
      {options.map((it) => (
        <BubbleCheckBox
          key={it.value}
          text={it.text}
          isActive={selected.includes(it.value)}
          onClick={toggleOption(it.value)}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export const BubbleCheckBoxesList = React.memo(BubbleCheckBoxesListView);
