import { ONCE } from 'src/types/once';

export const mapDecisionToText = {
  [ONCE.ContentDecisionType.Approve]: 'Approved',
  [ONCE.ContentDecisionType.ToModeration]: 'To moderator',
  [ONCE.ContentDecisionType.Reject]: 'Deleted',
  [ONCE.ContentDecisionType.Change]: 'Changed',
};

const mapContentSourceToText = {
  [ONCE.ContentDecisionSource.About]: 'About me',
  [ONCE.ContentDecisionSource.Name]: 'Name',
  [ONCE.ContentDecisionSource.Occupation]: 'Work',
};

export const getContentTypeText = (
  contentType: ONCE.ContentDecisionConentType,
  contentSource?: ONCE.ContentDecisionSource,
) => {
  if (contentType === ONCE.ContentDecisionConentType.Photo) {
    return 'Photo';
  }

  if (contentType === ONCE.ContentDecisionConentType.Thumbnail) {
    return 'Main';
  }

  if (contentSource) {
    return mapContentSourceToText[contentSource];
  }

  return '';
};
