import React from 'react';
import clsx from 'clsx';

import { ONCE } from 'src/types/once';
import { formatDate } from 'src/components/Moderation/screens/UserModeration/once/utils/format-date';

import { mapProfileGroupToText } from '../../profile/content/profile-labels';

import { useStyles } from './styles';

type Props = {
  data: ONCE.GroupsDecisions;
};

export const ModerationProfileGroupItem = (props: Props) => {
  const { data } = props;

  const { reason, group, timestamp, initiatedBy } = data;

  const classes = useStyles({ group });

  return (
    <>
      <div className={classes.container}>
        <div className={classes.baseText}>{timestamp ? formatDate(timestamp) : 'Scheduled'}</div>
        <div className={clsx(classes.baseText, classes.initiatedByText)}>{initiatedBy}</div>
        <div className={clsx(classes.baseText, classes.groupText)}>{mapProfileGroupToText[group]}</div>
        <div className={classes.reasonContainer}>
          <div className={clsx(classes.baseText, classes.reasonText)}>{reason}</div>
        </div>
      </div>
    </>
  );
};
