import React from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

type Props = {
  selectedReasons: Record<string, boolean>;
  reasons: { id: string; label: string }[];
  setReason: (key: string, value: boolean) => void;
};

export const ChangeGroupDialogReasons = React.memo((props: Props) => {
  const { reasons, setReason, selectedReasons } = props;

  const classes = useStyles();

  const onCategoryClick = (id: string) => {
    const selected = selectedReasons?.[id];

    setReason(id, !selected);
  };

  return (
    <div className={classes.container}>
      {reasons.map(({ id, label }) => {
        const selected = selectedReasons?.[id];

        return (
          <button
            type="button"
            key={id}
            className={clsx([classes.buttonContainer, selected && classes.itemRedContainer])}
            onClick={() => onCategoryClick(id)}
          >
            <span className={clsx([classes.itemText, selected && classes.itemRedText])}>{label}</span>
          </button>
        );
      })}
    </div>
  );
});
