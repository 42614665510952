import { Http } from 'src/network/http';
import { ONCE } from 'src/types/once';

import { adapter } from '../adapter';

import { OnceModerationGroup } from './types';

const getModerationContentDecisions = (userId: string, decision?: string) => {
  return Http.shared()
    .instance.get<ONCE.ModerationContentDecisions>(
      `/moderation/intelligence/${userId}/content/decisions${decision ? '/' + decision : ''}`,
    )
    .then(adapter.getResponseData);
};

const getModerationGroupsDecisions = (userId: string) => {
  return Http.shared()
    .instance.get<ONCE.ModerationGroupsDecisions>(`/moderation/intelligence/${userId}/groups/decisions`)
    .then(adapter.getResponseData);
};

const setOnceModerationGroup = (userId: string, group: OnceModerationGroup) => {
  return Http.shared()
    .instance.post(`/once/moderation/${userId}/group`, {
      group,
    })
    .then(adapter.getModerationContent);
};

const getModerationQueueByUserId = (userId: string) => {
  return Http.shared()
    .instance.post<ONCE.ModerationQueueNext | null>(`moderation/intelligence/manual/queue/user/${userId}`)
    .then(adapter.getResponseData);
};

const getModerationQueueNext = () => {
  return Http.shared()
    .instance.post<ONCE.ModerationQueueNext | null>(`/moderation/intelligence/manual/queue/next`)
    .then(adapter.getResponseData);
};

const getModerationQueueCount = () => {
  return Http.shared()
    .instance.get<ONCE.ModerationQueueCount>(`/moderation/intelligence/manual/queue/count`)
    .then(adapter.getResponseData);
};

const getModerationProfilesCount = (date: string) => {
  return Http.shared()
    .instance.get<ONCE.ModerationProfilesCount>(`/moderation/intelligence/manual/profiles/count?date=${date}`)
    .then(adapter.getResponseData);
};

const postModerationResult = (userid: string, payload: ONCE.ModerationResult) => {
  return Http.shared()
    .instance.post<ONCE.ModerationQueueCount>(`/moderation/intelligence/manual/${userid}`, payload)
    .then(adapter.getResponseData);
};

export const onceModerationRequest = {
  getModerationContentDecisions,
  getModerationGroupsDecisions,
  getModerationProfilesCount,
  getModerationQueueByUserId,
  getModerationQueueCount,
  getModerationQueueNext,
  postModerationResult,
  setOnceModerationGroup,
};
