import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { useActionsContext, useDataContext } from 'src/components/Moderation/screens/UserModeration/once/hooks';

import { useStyles } from './styles';
import { ModerationProfileHistoryItem } from './item';

export const UserModerationHistory = () => {
  const classes = useStyles();

  const { profileData, decisionsHistory } = useDataContext();
  const { loadDecisionsHistory, setModerationMode } = useActionsContext();

  const onHistoryGoBack = () => {
    setModerationMode(null);
  };

  useEffect(() => {
    loadDecisionsHistory();
  }, [loadDecisionsHistory]);

  return (
    <>
      <Button variant="outlined" className={classes.backButton} onClick={onHistoryGoBack}>
        <ArrowBack />
      </Button>

      {profileData?.id && (
        <div className={classes.contentContainer}>
          {decisionsHistory.map((decision) => {
            return <ModerationProfileHistoryItem key={decision.contentId} data={decision} userId={profileData.id} />;
          })}
        </div>
      )}
    </>
  );
};
