import React, { forwardRef } from 'react';

import { ONCE } from 'src/types/once';

import { useStyles } from './styles';
import { OnceModerationContentCardText } from './card-text';
import { OnceModerationContentCardPhoto } from './card-photo';

type Props = {
  contentDecision: ONCE.ContentDecision[];
  isAdministrator: boolean;
  userId: string;
};

export const OnceModerationContent = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { contentDecision, isAdministrator, userId } = props;

  const classes = useStyles();

  if (!contentDecision.length) return null;

  return (
    <div ref={ref} className={classes.container}>
      <span className={classes.titleText}>🚫 Deleted</span>

      <div className={classes.cardsContainer}>
        {contentDecision.map((decision) => {
          const { contentId, contentType } = decision;

          switch (contentType) {
            case ONCE.ContentDecisionConentType.Text: {
              return <OnceModerationContentCardText key={String(contentId)} decision={decision} />;
            }
            case ONCE.ContentDecisionConentType.Photo: {
              return (
                <OnceModerationContentCardPhoto
                  key={String(contentId)}
                  decision={decision}
                  userId={userId}
                  isAdministrator={isAdministrator}
                />
              );
            }
            default: {
              return null;
            }
          }
        })}
      </div>
    </div>
  );
});
