import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',

      '@media(max-width: 1096px)': {
        padding: '0 16px',
      },

      '@media(max-width: 680px)': {
        flexDirection: 'column',
        gap: 24,
      },
    },

    profileContainer: {
      width: 384,
    },
  };
});
