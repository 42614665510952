import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { ONCE } from 'src/types/once';
import { useActionsContext, useDataContext } from 'src/components/Moderation/screens/UserModeration/once/hooks';

import { ChangeGroupDialog } from './dialog';
import { ChangeGroupFooter } from './footer';
import { ModerationProfileGroupItem } from './item';
import { useStyles } from './styles';

const getScheduledDecision = (group: ONCE.ModerationProfileGroup, profileGroupReason: string): ONCE.GroupsDecisions => {
  return {
    timestamp: '',
    initiatedBy: 'Moderator',
    group,
    reason: profileGroupReason,
  };
};

export const UserModerationChangeGroup = () => {
  const classes = useStyles();

  const [modalType, setModalType] = useState<ONCE.ModerationProfileGroup | null>(null);

  const { groupsDecisions, initialProfileGroup, profileGroup, profileGroupReason } = useDataContext();
  const { loadGroupsDecisions, setModerationMode, resetProfileGroup, setProfileGroup } = useActionsContext();

  const onHistoryGoBack = () => {
    setModerationMode(null);
  };

  const handleClose = () => {
    setModalType(null);
  };

  const onReset = () => {
    resetProfileGroup();
  };

  const onGroupPress = (group: ONCE.ModerationProfileGroup) => {
    if (initialProfileGroup === group) {
      onReset();
      return;
    }

    setModalType(group);
  };

  const handleSubmitDialog = (group: ONCE.ModerationProfileGroup, reason: string) => {
    setProfileGroup(group, reason);
    setModalType(null);
  };

  useEffect(() => {
    loadGroupsDecisions();
  }, [loadGroupsDecisions]);

  const resetGroupDisabled = initialProfileGroup === profileGroup;

  const decisions = resetGroupDisabled
    ? groupsDecisions
    : [getScheduledDecision(profileGroup, profileGroupReason), ...groupsDecisions];

  return (
    <>
      <div>
        <Button variant="outlined" className={classes.backButton} onClick={onHistoryGoBack}>
          <ArrowBack />
        </Button>

        <div className={classes.contentContainer}>
          {decisions.map((decision) => {
            return <ModerationProfileGroupItem key={decision.timestamp} data={decision} />;
          })}
        </div>
      </div>

      <ChangeGroupFooter
        onGroupPress={onGroupPress}
        onReset={onReset}
        profileGroup={profileGroup}
        resetDisabled={resetGroupDisabled}
      />

      {modalType && <ChangeGroupDialog onClose={handleClose} onSubmit={handleSubmitDialog} profileGroup={modalType} />}
    </>
  );
};
