import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  container: {
    width: '192px',
    height: '256px',
    borderRadius: '12px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #EDEDED',
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
    position: 'relative',
    cursor: 'pointer',
    padding: '0px',
    overflow: 'hidden',

    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },

  photo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  authorContainer: {
    position: 'absolute',
    top: 8,
    left: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '8px',
    height: 24,
    padding: '0px 12px',
    display: 'flex',
    alignItems: 'center',
  },

  authorText: {
    fontFamily: 'Poppins',
    fontSize: '10px',
    lineHeight: '15px',
    color: '#FFFFFF',
  },
});
