import React, { useMemo, useState } from 'react';
import { Button, IconButton, OutlinedInput } from '@material-ui/core';
import { CloseIcon } from '@material-ui/data-grid';

import { ONCE } from 'src/types/once';
import { ModerationDialogModal } from 'src/components/Moderation/screens/UserModeration/once/components/dialog-modal';

import { mapProfileGroupToText } from '../../profile/content/profile-labels';

import { ChangeGroupDialogReasons } from './reasons';
import { getChangeGroupReasons, getReasonFromSelected } from './utils';
import { useStyles } from './styles';

const inputProps = { maxLength: 300 };

type Props = {
  onClose: () => void;
  onSubmit: (group: ONCE.ModerationProfileGroup, reason: string) => void;
  profileGroup: ONCE.ModerationProfileGroup;
};

export const ChangeGroupDialog = (props: Props) => {
  const { profileGroup, onClose, onSubmit } = props;

  const classes = useStyles();

  const [selectedReasons, setSelectedReasons] = useState<Record<string, boolean>>({});
  const [customReason, setCustomReason] = useState('');

  const setReason = (key: string, value: boolean) => {
    setSelectedReasons((prevReasons) => {
      const newReasons = { ...prevReasons };

      if (!value) {
        delete newReasons[key];
      } else {
        newReasons[key] = value;
      }

      return newReasons;
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;

    setCustomReason(newValue);
  };

  const handleBlur = () => {
    if (customReason.trim().length === 0) {
      setCustomReason('');
    }
  };

  const handleSubmit = () => {
    const reason = getReasonFromSelected(selectedReasons, customReason);

    onSubmit(profileGroup, reason);
  };

  const { reasonsList } = getChangeGroupReasons(profileGroup);

  const changeGroupDisabled = useMemo(() => {
    return !Object.values(selectedReasons).some((item) => item) && customReason.length < 1;
  }, [customReason.length, selectedReasons]);

  return (
    <>
      <ModerationDialogModal open onClose={onClose} contentClassName={classes.contentContainer}>
        <span className={classes.modalTitle}>Choose Reasons</span>

        <ChangeGroupDialogReasons reasons={reasonsList} setReason={setReason} selectedReasons={selectedReasons} />

        <OutlinedInput
          placeholder="Write your own"
          value={customReason}
          onChange={handleChange}
          onBlur={handleBlur}
          className={classes.reasonInput}
          labelWidth={0}
          multiline
          fullWidth
          inputProps={inputProps}
        />

        <Button
          variant="outlined"
          className={classes.button}
          onClick={() => handleSubmit()}
          disabled={changeGroupDisabled}
        >
          {`Change to ${mapProfileGroupToText[profileGroup]}`}
        </Button>

        <IconButton aria-label="close" onClick={onClose} className={classes.modalIconContainer}>
          <CloseIcon className={classes.modalIcon} />
        </IconButton>
      </ModerationDialogModal>
    </>
  );
};
