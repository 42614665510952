import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() => {
  return {
    container: {
      flexGrow: 1,
      display: 'flex',
      flexWrap: 'wrap',
      padding: '24px',
      boxSizing: 'border-box',
      gap: '24px 16px',
      maxWidth: '656px',

      '@media(max-width: 1096px)': {
        maxWidth: '448px',
      },
    },

    containerActive: {
      backgroundColor: '#FFFFFF',
      borderRadius: '12px',
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px #EDEDED inset',
    },
  };
});
