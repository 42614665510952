import React from 'react';

import {
  useDataContext,
  useActionsContext,
  useModerationTimeout,
} from 'src/components/Moderation/screens/UserModeration/once/hooks';

import { ModerationProfileContent } from './content';
import { ModerationProfileFooter } from './footer';
import { ModerationProfileHeader } from './header';
import { ModerationProfilePlaceholder } from './placeholder';
import { ModerationTimeoutModal } from './timeout-modal';
import { useStyles } from './styles';

export const UserModerationProfile = () => {
  const classes = useStyles();

  const {
    errorCode,
    isAdministrator,
    isModerationByUserId,
    profileData,
    profilesCount,
    queueCount,
    resetDisabled,
    userIdParams,
  } = useDataContext();
  const { onSave, onDelete, onReset, loadNextProfile } = useActionsContext();

  const { timeoutModalVisible, onTimeoutModalClose } = useModerationTimeout(!isModerationByUserId, profileData?.id);

  const searchFieldEnabled = isAdministrator && isModerationByUserId;

  return (
    <>
      <div className={classes.contentContainer}>
        <ModerationProfileHeader
          profilesCount={profilesCount}
          queueCount={queueCount}
          searchFieldEnabled={searchFieldEnabled}
        />

        {profileData ? (
          <ModerationProfileContent profileData={profileData} />
        ) : (
          <ModerationProfilePlaceholder
            errorCode={errorCode}
            isAdministrator={isAdministrator}
            isModerationByUserId={isModerationByUserId}
            loadNextProfile={loadNextProfile}
            userIdParams={userIdParams}
          />
        )}
      </div>

      {profileData && (
        <ModerationProfileFooter
          isModerationByUserId={isModerationByUserId}
          onDelete={onDelete}
          onReset={onReset}
          onSave={onSave}
          resetDisabled={resetDisabled}
        />
      )}

      {timeoutModalVisible && <ModerationTimeoutModal onTimeoutModalClose={onTimeoutModalClose} />}
    </>
  );
};
