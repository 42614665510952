import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() => {
  return {
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },

    genderContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },

    nameContainer: {
      width: 'calc((100% - 80px))',
    },

    infoContainer: {
      padding: '12px 24px',
      backgroundColor: '#FFFFFF',
      borderRadius: '12px',
      border: '1px solid #EDEDED',
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
    },

    infoText: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      wordWrap: 'break-word',
      overflow: 'hidden',
    },
  };
});
