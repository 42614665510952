import { onceModerationRequest } from 'src/network/moderation/once';
import { useCallback, useState } from 'react';

export const useModerationProfilesCount = () => {
  const [profilesCount, setModeratedUsersCount] = useState<number>(0);

  const fetchModerationProfilesCount = useCallback(async () => {
    try {
      const result = await onceModerationRequest.getModerationProfilesCount(new Date().toISOString());
      setModeratedUsersCount(result.moderatedProfiles);

      return result.moderatedProfiles;
    } catch (e) {
      return 0;
    }
  }, []);

  const updateModeratedProfilesCount = useCallback(() => {
    setModeratedUsersCount((prev) => prev + 1);
  }, []);

  return {
    fetchModerationProfilesCount,
    profilesCount,
    updateModeratedProfilesCount,
  };
};
