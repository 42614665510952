import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { AIDecisionPhotoType, PhotoHistoryItem } from 'src/network/flure-ai-decision/types';

import { PhotoItem } from '../photo-item';
import { useStyles } from './styles';

type Props = {
  data: PhotoHistoryItem[];
  photoType: AIDecisionPhotoType;
  loadMore: () => void;
};

export const PhotosList = React.memo(({ data, photoType, loadMore }: Props) => {
  const classes = useStyles();

  return (
    <div id="scrollableDiv" className={classes.contentContainer}>
      <InfiniteScroll
        dataLength={data.length}
        next={loadMore}
        className={classes.verificationPhotos}
        hasMore
        loader={null}
        scrollableTarget="scrollableDiv"
        hasChildren
      >
        {data.map((item) => (
          <PhotoItem key={item.baseName} item={item} photoType={photoType} />
        ))}
      </InfiniteScroll>
    </div>
  );
});
