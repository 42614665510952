import React from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';

import { useKeyListener } from 'src/components/Moderation/screens/UserModeration/once/hooks';

import { ModerationProfileFooterDelete } from './delete-button';
import { useStyles } from './styles';

type Props = {
  isModerationByUserId: boolean;
  onDelete: () => void;
  onReset: () => void;
  onSave: () => void;
  resetDisabled: boolean;
};

export const ModerationProfileFooter = (props: Props) => {
  const { isModerationByUserId, resetDisabled, onReset, onSave, onDelete } = props;

  const classes = useStyles();

  useKeyListener({ approveAction: onSave });

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.leftContainer}>
          <ModerationProfileFooterDelete onDeleteUser={onDelete} />

          <Button
            variant="outlined"
            className={clsx(classes.baseButton, classes.resetButton)}
            onClick={onReset}
            disabled={resetDisabled}
          >
            Reset
          </Button>
        </div>
        <Button variant="outlined" className={clsx(classes.baseButton, classes.saveButton)} onClick={onSave}>
          <div className={classes.saveButtonTextContainer}>
            {isModerationByUserId ? (
              'Save'
            ) : (
              <>
                Save and Next
                <span className={classes.saveButtonSecondaryText}>Space</span>
              </>
            )}
          </div>
        </Button>
      </div>
    </div>
  );
};
