import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { UserRoles } from 'src/types/user/roles';
import { useAuth } from 'src/services/auth';

import {
  useContentToModerate,
  useActionsToModerate,
  useProfileToModerate,
  useProfileContentDecisions,
  useProfileGroupsDecisions,
} from '../hooks';
import { getModerationSubmitPayload, sortAndFilterProfileMedia } from '../utils';

import { ActionsContext, DataContext } from './contexts';
import { ActionsContextType, DataContextType, ModerationMode } from './types';

export const ModerationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { userId: userIdParams } = useParams<{ userId?: string }>();
  const { me } = useAuth();

  const isModerationByUserId = Boolean(userIdParams);
  const isAdministrator = useMemo(() => isModerationByUserId || (me?.roles || []).includes(UserRoles.Supervisor), [
    me?.roles,
    isModerationByUserId,
  ]);

  const [moderationMode, setModerationMode] = useState<ModerationMode | null>(null);

  const {
    errorCode,
    isQueueLoading,
    loadNextProfile,
    moderationContent,
    onModerationSubmit,
    processing,
    profileData,
    profilesCount,
    queueCount,
  } = useProfileToModerate(isModerationByUserId);

  const {
    initialProfileData,
    profileGender,
    profileGroup,
    profileGroupReason,
    profileThumbnail,
    resetActions,
    resetProfileGroup,
    setProfileGroup,
    setProfileThumbnail,
    toggleUserGender,
  } = useActionsToModerate(profileData);

  const { contentToModerate, setModerationContent, resetContent } = useContentToModerate(moderationContent);

  const { rejectedDecisions, decisionsHistory, loadDecisionsHistory } = useProfileContentDecisions(profileData?.id);
  const { groupsDecisions, loadGroupsDecisions } = useProfileGroupsDecisions(profileData?.id);

  const profileMedia = useMemo(() => {
    return sortAndFilterProfileMedia(
      contentToModerate.newMedia,
      moderationContent?.newMedia,
      profileData?.thumbnail,
      rejectedDecisions,
    );
  }, [moderationContent?.newMedia, contentToModerate.newMedia, profileData?.thumbnail, rejectedDecisions]);

  const isProfileUnchanged = useMemo(() => {
    return isEqual(
      { ...initialProfileData, moderationContent },
      {
        profileGender,
        profileGroup,
        profileGroupReason,
        profileThumbnail,
        moderationContent: contentToModerate,
      },
    );
  }, [
    contentToModerate,
    initialProfileData,
    moderationContent,
    profileGender,
    profileGroup,
    profileGroupReason,
    profileThumbnail,
  ]);

  const submitPayload = useMemo(() => {
    if (!profileData) return null;

    return getModerationSubmitPayload(
      profileData,
      {
        profileGender,
        profileThumbnail,
        profileGroup: {
          group: profileGroup,
          reason: profileGroupReason,
        },
      },
      contentToModerate,
    );
  }, [profileData, profileThumbnail, profileGender, profileGroup, profileGroupReason, contentToModerate]);

  const resetDisabled = !submitPayload || isProfileUnchanged;

  const onReset = useCallback(() => {
    resetActions();
    resetContent();
  }, [resetActions, resetContent]);

  const onDelete = useCallback(() => {
    onModerationSubmit({ actions: [{ name: 'delete-profile' }], userContents: [] }, true);
  }, [onModerationSubmit]);

  const onSave = useCallback(() => {
    if (submitPayload) {
      onModerationSubmit(submitPayload, false);
    }
  }, [onModerationSubmit, submitPayload]);

  useEffect(() => {
    loadNextProfile(userIdParams);
  }, [userIdParams, loadNextProfile]);

  const dataValue = useMemo((): DataContextType => {
    return {
      contentToModerate,
      decisionsHistory,
      errorCode,
      groupsDecisions,
      initialProfileGroup: initialProfileData.profileGroup,
      isAdministrator,
      isModerationByUserId,
      isQueueLoading,
      moderationMode,
      processing,
      profileData,
      profileGender,
      profileGroup,
      profileGroupReason,
      profileMedia,
      profileThumbnail,
      profilesCount,
      queueCount,
      rejectedDecisions,
      resetDisabled,
      userIdParams,
    };
  }, [
    contentToModerate,
    decisionsHistory,
    errorCode,
    groupsDecisions,
    initialProfileData.profileGroup,
    isAdministrator,
    isModerationByUserId,
    isQueueLoading,
    moderationMode,
    processing,
    profileData,
    profileGender,
    profileGroup,
    profileGroupReason,
    profileMedia,
    profileThumbnail,
    profilesCount,
    queueCount,
    rejectedDecisions,
    resetDisabled,
    userIdParams,
  ]);

  const actionsValue = useMemo((): ActionsContextType => {
    return {
      loadDecisionsHistory,
      loadGroupsDecisions,
      loadNextProfile,
      onDelete,
      onReset,
      onSave,
      resetProfileGroup,
      setModerationContent,
      setModerationMode,
      setProfileGroup,
      setProfileThumbnail,
      toggleUserGender,
    };
  }, [
    loadDecisionsHistory,
    loadGroupsDecisions,
    loadNextProfile,
    onDelete,
    onReset,
    onSave,
    resetProfileGroup,
    setModerationContent,
    setProfileGroup,
    setProfileThumbnail,
    toggleUserGender,
  ]);

  return (
    <DataContext.Provider value={dataValue}>
      <ActionsContext.Provider value={actionsValue}>{children}</ActionsContext.Provider>
    </DataContext.Provider>
  );
};
