import React from 'react';
import clsx from 'clsx';
import { Dialog, DialogContent } from '@material-ui/core';

import { useStyles } from './styles';

type Props = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  contentClassName?: string;
};

export const ModerationDialogModal = (props: Props) => {
  const { open, onClose, children, contentClassName } = props;

  const classes = useStyles();

  return (
    <Dialog fullScreen open={open} onClose={onClose} PaperProps={{ className: classes.modalContainer }}>
      <DialogContent
        className={classes.modalContentContainer}
        onClick={(event) => {
          if (event.target === event.currentTarget) {
            onClose();
          }
        }}
      >
        <div className={clsx(classes.contentContainer, contentClassName)}>{children}</div>
      </DialogContent>
    </Dialog>
  );
};
