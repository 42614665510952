import React, { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { format, isWithinInterval, isBefore } from 'date-fns';
import { CircularProgress } from '@material-ui/core';

import { Layout } from 'src/components/App/views/Layout';
import { ActionButtons } from 'src/components/LocalEvents/views/action-buttons';
import { FilterButtons, FilterType } from 'src/components/LocalEvents/views/filter-buttons';
import { TopContainer } from 'src/components/LocalEvents/views/top-container';
import { PromoCodeField, emptyRow, promoCodeFields, dateFormat } from 'src/components/LocalEvents/types-and-constants';
import { TableTh } from 'src/components/LocalEvents/views/table-th';
import { TableEditableTd } from 'src/components/LocalEvents/views/table-editable-td';
import { DeleteEventModal } from 'src/components/LocalEvents/views/delete-event-modal';
import { TableStaticTd } from 'src/components/LocalEvents/views/table-static-td';
import { usePromoCodes } from 'src/services/flure-promocodes/hooks';
import { PromoCodeData } from 'src/network/flure-promocodes/types';

import { useStyles } from './styles';

const numberRegExp = /^\d*$/;

export const LocalEventsScreen = () => {
  const classes = useStyles();
  const { promoCodesState, fetchPromoCodes, deletePromoCode, addOrUpdatePromoCode } = usePromoCodes();
  const [promoCodes, setPromoCodes] = useState<PromoCodeData[]>([]);
  const [data, setData] = useState<PromoCodeData[]>([]);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [idEditEnabled, setIdEditEnabled] = useState<boolean>(false);
  const [formData, setFormData] = useState<Partial<PromoCodeData>>({});
  const [errors, setErrors] = useState<Partial<Record<keyof PromoCodeData, boolean>>>({});
  const [newEventId, setNewEventId] = useState<string | null>(null);
  const [activeFilterType, setActiveFilterType] = useState<FilterType | undefined>();
  const [isModalOpen, setModalOpen] = useState(false);
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [deletingEventName, setDeletingEventName] = useState<string>('');

  const handleEdit = useCallback(
    (id: string) => {
      setEditingId(id);
      setIdEditEnabled(false);
      const rowData = data.find((item) => item.id === id);
      if (rowData) {
        setFormData(rowData);
      }
    },
    [data],
  );

  const handleSave = (id: string) => {
    const newErrors: Partial<Record<keyof PromoCodeData, boolean>> = {};
    let hasError = false;

    (Object.keys(formData) as (keyof PromoCodeData)[]).forEach((key) => {
      const isActiveUntilError =
        key === 'activeUntil' &&
        formData.activeUntil &&
        formData.activeFrom &&
        isBefore(new Date(formData.activeUntil), new Date(formData.activeFrom));
      const isDaysError = key === 'subTTL' && formData.subTTL && Number(formData.subTTL) < 1;

      if (!formData[key] || isActiveUntilError || isDaysError) {
        newErrors[key] = true;
        hasError = true;
      }
    });

    if (hasError) {
      setErrors(newErrors);
    } else {
      addOrUpdatePromoCode(formData as PromoCodeData, () => {
        const updatedData = data.map((item) => (item.id === id ? (formData as PromoCodeData) : item));
        const updatedPromoCodes = promoCodes.map((item) => (item.id === id ? (formData as PromoCodeData) : item));
        setData(updatedData);
        setPromoCodes(updatedPromoCodes);
        setEditingId(null);
        setErrors({});
      });
    }
  };

  const handleDelete = (id: string) => {
    deletePromoCode(id, () => {
      const updatedData = data.filter((item) => item.id !== id);
      const updatedPromoCodes = promoCodes.filter((item) => item.id !== id);
      setData(updatedData);
      setPromoCodes(updatedPromoCodes);
    });
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (name === PromoCodeField.subTTL && value && !numberRegExp.test(value)) {
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value) {
      setErrors({
        ...errors,
        [name]: false,
      });
    }
  };

  const handleDateChange = (day: Date, name: PromoCodeField) => {
    setFormData({
      ...formData,
      [name]: day ? format(day, dateFormat) : '',
    });

    if (day) {
      setErrors({
        ...errors,
        [name]: false,
      });
    }
  };

  const handleAddNewEvent = () => {
    const newId = `new_event_${Date.now()}`;
    const updatedData = [{ ...emptyRow, id: newId }, ...data];
    const updatedPromoCodes = [{ ...emptyRow, id: newId }, ...promoCodes];
    setData(updatedData);
    setPromoCodes(updatedPromoCodes);
    setNewEventId(newId);
  };

  const onFilterPress = (filterType: FilterType) => () => {
    if (activeFilterType === filterType) {
      setActiveFilterType(undefined);
    } else {
      setActiveFilterType(filterType);
    }
  };

  const toggleModal = () => setModalOpen((isOpen) => !isOpen);

  const handleDeleteAndCloseModal = () => {
    handleDelete(deletingId!);
    toggleModal();
    if (deletingId === editingId) {
      setEditingId(null);
    }
  };

  const handleCloseModal = () => {
    toggleModal();
    setDeletingId(null);
    setDeletingEventName('');
  };

  const onDeleteButtonPress = (itemId: string, eventName: string) => {
    toggleModal();
    setDeletingId(itemId);
    setDeletingEventName(eventName);
  };

  useEffect(() => {
    if (newEventId) {
      handleEdit(newEventId);
      setIdEditEnabled(true);
      setNewEventId(null);
    }
  }, [handleEdit, newEventId]);

  useEffect(() => {
    if (activeFilterType) {
      setData(
        promoCodes.filter((it) => {
          if (!it.activeFrom || !it.activeUntil) {
            return true;
          }

          const isActiveEvent = isWithinInterval(new Date(), {
            start: new Date(it.activeFrom),
            end: new Date(it.activeUntil),
          });

          return activeFilterType === FilterType.ActiveEvents ? isActiveEvent : !isActiveEvent;
        }),
      );
    } else {
      setData(promoCodes);
    }
  }, [activeFilterType, promoCodes]);

  useEffect(() => {
    if (promoCodesState.value) {
      setPromoCodes(promoCodesState.value);
      setData(promoCodesState.value);
    }
  }, [promoCodesState]);

  useEffect(() => {
    fetchPromoCodes();
  }, [fetchPromoCodes]);

  return (
    <>
      <DeleteEventModal
        isOpen={isModalOpen}
        onConfirm={handleDeleteAndCloseModal}
        onReject={handleCloseModal}
        eventName={deletingEventName}
      />
      <Layout containerSize="xl">
        <div className={classes.container}>
          <TopContainer data={data} onButtonPress={handleAddNewEvent} buttonDisabled={editingId !== null} />
          <table className={classes.table}>
            <thead>
              <tr>
                {promoCodeFields.map((field) => (
                  <TableTh key={field} field={field} />
                ))}
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {promoCodesState.loading && (
                <tr className={classes.loadingTr}>
                  <td>
                    <CircularProgress className={classes.loader} />
                  </td>
                </tr>
              )}
              {data.map((item) => (
                <tr key={item.id}>
                  {editingId === item.id ? (
                    <>
                      {promoCodeFields.map((field) => (
                        <TableEditableTd
                          key={field}
                          errors={errors}
                          field={field}
                          formData={formData}
                          handleChange={handleChange}
                          handleDateChange={handleDateChange}
                          idEditEnabled={idEditEnabled}
                        />
                      ))}
                      <td>
                        <ActionButtons
                          onSave={() => handleSave(item.id)}
                          onDelete={() => onDeleteButtonPress(item.id, item.eventName)}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      {promoCodeFields.map((field) => (
                        <TableStaticTd key={field} item={item} field={field} />
                      ))}
                      <td>
                        <ActionButtons
                          onDelete={() => onDeleteButtonPress(item.id, item.eventName)}
                          onEdit={() => handleEdit(item.id)}
                        />
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Layout>
      <FilterButtons activeFilterType={activeFilterType} onFilterPress={onFilterPress} />
    </>
  );
};
