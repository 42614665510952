import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() => {
  return {
    placeholderContainer: {
      height: '70vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    progress: {
      color: '#A8A8A8',
    },

    notFoundTitle: {
      textAlign: 'center',
      fontSize: '120px',
      lineHeight: '180px',
    },

    notFoundSubtitle: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#A8A8A8',
      textAlign: 'center',
    },

    tryAgainTitle: {
      marginTop: '16px',
      color: '#212121',
      textAlign: 'center',
      fontSize: '120px',
      lineHeight: '180px',
    },

    tryAgainSubtitle: {
      fontWeight: 700,
      marginTop: '16px',
      color: '#212121',
      textAlign: 'center',
    },

    placeholderTitle: {
      color: '#121212',
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '48px',
      marginBottom: '4px',
    },

    placeholderSubtitle: {
      textAlign: 'center',
      color: '#121212',
      fontFamily: 'Poppins',
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: '32px',
    },

    button: {
      backgroundColor: '#000000',
      height: '64px',
      width: '400px',
      borderRadius: '8px',
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',

      '&:hover': {
        backgroundColor: '#000000',
        opacity: 0.8,
      },

      '& .MuiButton-label': {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FFFFFF',
        textTransform: 'none',
      },
    },
  };
});
