import { ONCE } from 'src/types/once';

import { ModerationContent, ModerationContentItem, ModerationProfileData } from './types';

type SubmitPayloadActionType = 'set-gender' | 'set-thumbnail' | 'set-group';

type SubmitPayloadAction = { name: SubmitPayloadActionType; value: string; reason?: string };

type SubmitPayloadContent = {
  contentId: number;
  contentType: ONCE.ContentDecisionConentType;
  categories: string[];
};

export type SubmitPayload = {
  actions: SubmitPayloadAction[];
  userContents: SubmitPayloadContent[];
};

export const getModerationSubmitPayload = (
  profileData: ModerationProfileData,
  userActions: {
    profileGender?: string;
    profileThumbnail?: string;
    profileGroup?: {
      group: ONCE.ModerationProfileGroup;
      reason: string;
    };
  },
  contentToModerate: ModerationContent,
): SubmitPayload => {
  const actions: SubmitPayloadAction[] = [];
  const userContents: SubmitPayloadContent[] = [];

  if (userActions.profileGender && profileData?.gender !== userActions.profileGender) {
    actions.push({ name: 'set-gender', value: userActions.profileGender });
  }

  if (userActions.profileThumbnail && profileData?.thumbnail !== userActions.profileThumbnail) {
    actions.push({ name: 'set-thumbnail', value: userActions.profileThumbnail });
  }

  if (userActions.profileGroup && profileData?.group !== userActions.profileGroup.group) {
    actions.push({
      name: 'set-group',
      value: userActions.profileGroup.group,
      reason: userActions.profileGroup.reason,
    });
  }

  Object.entries(contentToModerate).forEach(([key, value]) => {
    if (key === 'newMedia' && value) {
      Object.values(value as Record<string, ModerationContentItem>).forEach((mediaValue) => {
        const moderatedContent = extractModeratedContent(mediaValue);
        if (moderatedContent) {
          userContents.push(moderatedContent);
        }
      });
    } else {
      const moderatedContent = extractModeratedContent(value as ModerationContentItem);
      if (moderatedContent) {
        userContents.push(moderatedContent);
      }
    }
  });

  return {
    actions,
    userContents,
  };
};

const extractModeratedContent = (contentItem: ModerationContentItem | undefined): SubmitPayloadContent | null => {
  if (contentItem?.isModerated || contentItem?.isToModerate) {
    const categories = Object.keys(contentItem.categoryLevels).filter(
      (key) => contentItem.categoryLevels[key] === ONCE.ContentDecisionCategoryLevelType.Red,
    );

    return {
      contentId: contentItem.contentId,
      contentType: contentItem.contentType,
      categories,
    };
  }
  return null;
};
